import React, { useState, useCallback } from "react"
import AsyncCreatableSelect from "react-select/creatable"

function CreatableDropdown({ departmentOptions, setDepartment }) {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { departmentSelected } = globalState
  const [value, setValue] = useState({})
  const [options, setOptions] = useState(departmentOptions)

  const handleChange = useCallback(inputValue => {
    if (inputValue === null) {
      setValue({ value: "", label: "" })
      setDepartment({
        value: ""
      })
      return
    }
    setValue(inputValue)
    setDepartment({
      value: inputValue.value
    })
  }, [])

  const handleCreate = useCallback(
    inputValue => {
      const newValue = { value: inputValue, label: inputValue }
      setOptions([...options, newValue])
      setValue(newValue)
      setDepartment({
        isNew: true,
        value: inputValue
      })
    },
    [options]
  )

  React.useEffect(() => {
    let initialValue = {}
    if (departmentSelected && departmentSelected.id) {
      initialValue = {
        value: departmentSelected.id,
        label: departmentSelected.title
      }
      setValue(initialValue)
      setDepartment({
        value: initialValue.value
      })
    }
  }, [])

  const loadOptions = (inputValue, callback) =>
    setTimeout(() => {
      callback(
        options.filter(item =>
          item.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      )
    }, 3000)

  return (
    <div className="creatableDropdown">
      <AsyncCreatableSelect
        isClearable
        value={value}
        options={options}
        onChange={handleChange}
        onCreateOption={handleCreate}
        cacheOptions
        loadOptions={loadOptions}
      />
    </div>
  )
}

export default CreatableDropdown
