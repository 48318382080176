import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Container, Row, Form, Alert } from "react-bootstrap"
import SplashContent from "../SplashContent"
import { Link, useHistory } from "react-router-dom"
import { otp } from "../../Services/api"

import "./forgotPassword.scss"

function ForgotPassword() {
  const history = useHistory()
  const [formState, setFormState] = useState(true)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(undefined)

  const getOTP = async payload => {
    try {
      setLoading(true)
      const response = await otp(payload)
      history.push("/token-input", payload)
    } catch (error) {
      if (error.response) {
        setLoading(false)
        setError(error.response.data.detail)
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email Required")
    }),
    onSubmit: values => {
      getOTP(values)

      // setFormState(values);
    }
  })
  return (
    <SplashContent>
      <div className="how-to">How to?</div>
      <div className="outer-container">
        <div className="enter-cred-text">Forgot Password</div>
        <p className="passwd-reset-text">
          A password reset code will be sent your email account
        </p>
        <div className="login-form">
          {error && <Alert variant={"danger"}>{error}</Alert>}

          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Your Email"
              />
              <Form.Text className="text-danger">
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </Form.Text>
            </Form.Group>

            <Button
              className="signIn-btn"
              variant="primary"
              type="submit"
              onClick={values => setFormState(values)}
            >
              {loading ? <span>Sending...</span> : <span>Send</span>}
            </Button>
          </Form>
        </div>
      </div>

      <div className="create-account">
        <Link to="/login">Back To Login</Link>
      </div>
    </SplashContent>
  )
}

export default ForgotPassword
