import React from "react"
import { Button } from "react-bootstrap"
import Attachment from "../../Assets/images/attachments.png"

function AttachmentsCard({
  getAttachmentList,
  onFileChangeHandle,
  files,
  onClickUploadHandle,
  loading
}) {
  return (
    <div className="p-card attachments">
      <div className="header">
        <div style={{ display: 'flex', }} className="title">
          {/* <span className="icon"> */}
          <img src={Attachment} />
          {/* </span> */}
          <span className="text">Attachments</span>
        </div>

      </div>
      <div className="line"></div>
      <div className="body">
        <div className="downloads">{getAttachmentList()}</div>
      </div>
      <div className="attachments-footer">
        <div class="form-group file-area">
          <label for="images"></label>
          <input
            type="file"
            name="images"
            id="images"
            required="required"
            multiple="multiple"
            onChange={onFileChangeHandle}
          />
          <div class="file-dummy">
            <div class="success">
              {files.map(file => (
                <span>{file.name}</span>
              ))}
            </div>
            <div class="default">Please upload a file</div>
          </div>
          <div className="upload-btn">
            <Button onClick={onClickUploadHandle} disabled={loading}>
              {loading ? <span>Uploading...</span> : <span>Upload</span>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AttachmentsCard
