import React, { Suspense, useEffect } from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import AppRoute from "./config/router/AppRoute"
import routes from "./config/routing/routes"
import useUser from "./Context/UserContext"
import { useHistory } from "react-router-dom"
import { PrivateRoute } from "./Components/PrivateRoute/PrivateRoute"
import Main from "./Layouts/Main/Main"
const publicRoutes = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/token-input",
  "/"
]
export default function App() {
  const history = useHistory()
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { user } = globalState || {}

  useEffect(() => {
    if (globalState && globalState.token && user.profile_completed) {
      if (publicRoutes.indexOf(history.location.pathname) !== -1) {        
        history.push("/dashboard")
      }
    } else {
      history.push({
        pathname: history.location.pathname,
        search: history.location.search
      })
    }
  }, [])

  return (
    <Switch>
      {routes.map(route => (
        <AppRoute
          key={route.path}
          path={route.path}
          component={route.component}
          isPrivate={route.isPrivate}
          roles={["Employer"]}
        />
      ))}
      <PrivateRoute path="/" component={Main} />
    </Switch>
  )
}
