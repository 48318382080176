import React, { useEffect, useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import "./datepicker.scss"

function CustomDatePicker({ startDate, setStartDate, ...rest }) {
  return (
    <DatePicker
      yearDropdownItemNumber={100}
      scrollableYearDropdown={true}
      showYearDropdown
      showMonthDropdown
      selected={startDate}
      onChange={date => setStartDate(date)}
      {...rest}
    />
  )
}

export default CustomDatePicker
