import { Bar } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"
Chart.register(...registerables)

const BarChart = ({ id, chartData }) => {
  return (
    <div>
      <Bar
        id={id}
        data={chartData}
        options={{
          scales: {
            y: {
              ticks: {
                callback: function (value, index, ticks) {
                  return "$" + value
                }
              }
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }}
        height={"110%"}
      />
    </div>
  )
}

export default BarChart
