import Login from "../../Pages/Login"
import Signup from "../../Pages/Signup"
import ForgotPassword from "../../Pages/ForgotPassword"
import ResetPassword from "../../Pages/ResetPassword/ResetPassword"
import TokenInput from "../../Pages/TokenInput"
import CompanyDetails from "../../Pages/CompanyDetails"
import Dashboard from "../../Components/Dashboard"
import Main from "../../Layouts/Main/Main"

import {
  LOGIN_PATH,
  SIGNUP_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  TOKEN_INPUT_PATH,
  COMPANY_DETAILS_PATH,
  DASHBOARD_PATH
} from "./paths"

export const LOGIN = {
  component: Login,
  path: LOGIN_PATH,
  isPrivate: false
}
export const SIGNUP = {
  component: Signup,
  path: SIGNUP_PATH,
  isPrivate: false
}
export const FORGOT_PASSWORD = {
  component: ForgotPassword,
  path: FORGOT_PASSWORD_PATH,
  isPrivate: false
}

export const RESET_PASSWORD = {
  component: ResetPassword,
  path: RESET_PASSWORD_PATH,
  isPrivate: false
}

export const TOKEN_INPUT = {
  component: TokenInput,
  path: TOKEN_INPUT_PATH,
  isPrivate: false
}

export const COMPANY_DETAILS = {
  component: CompanyDetails,
  path: COMPANY_DETAILS_PATH,
  isPrivate: false
}

export const DASHBOARD = {
  component: Main,
  path: DASHBOARD_PATH,
  isPrivate: false
}

export default [
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  TOKEN_INPUT,
  COMPANY_DETAILS
]
