import React, { useState } from "react"
import Select, { components } from "react-select"
import DownArrow from "../../Assets/images/arrow_down.png"
import "./dropdown.scss"

export default function ({
  name,
  options = [{ value: "vanilla", label: "Vanilla" }],
  placeholder = "Select",
  width = "200px",
  onChangeHandler,
  ...rest
}) {
  // const [selectedOption, setSelectedOption] = useState("null")
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={DownArrow} />
      </components.DropdownIndicator>
    )
  }

  return (
    <div className="dropdown" style={{ width: width }}>
      <Select
        name={name}
        onChange={typeof onChangeHandler ? onChangeHandler : null}
        options={options}
        placeholder={placeholder}
        components={{ DropdownIndicator }}
        {...rest}
      />
    </div>
  )
}
