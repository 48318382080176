import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Container, Row, Form } from "react-bootstrap"
import OTPInput from "react-otp-input"
import SplashContent from "../SplashContent"
import { Link, useHistory, useLocation } from "react-router-dom"
import { verify } from "../../Services/api"

import "./tokenInput.scss"

function TokenInput() {
  const history = useHistory()

  const location = useLocation()
  const [formState, setFormState] = useState(true)
  const [OTP, setOTP] = useState("")

  const submitOTPHandler = async () => {
    try {
      if (location?.state?.email) {
        const response = await verify({
          email: location?.state?.email,
          otp: OTP
        })
        history.push({
          pathname: "/reset-password",
          search: `?token=${response.data.token}`
        })
        return
      }
      throw new Error("Email is missing!")
    } catch (error) {
      if (error && error.response && error.response.status == 400) {
        return alert(error.response.data.detail[0])
      }
      alert("Something went wrong. Try again to clik the link again.")
      // history.push({
      //   pathname: "/forgot-password"
      // })

      console.log("Error reseting password ", error)
    }
  }

  // useEffect(() => console.log(location), [])

  const formik = useFormik({
    initialValues: {
      token: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),

      email: Yup.string().email("Invalid email address").required("Required")
    }),
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2))
      // setFormState(values);
    }
  })
  return (
    <SplashContent>
      <div className="how-to">How to?</div>
      <div className="outer-container">
        <div className="enter-cred-text">Token Input</div>
        <p className="passwd-reset-text">
          Enter the code you receive on your email.
        </p>
        <Form onSubmit={formik.handleSubmit}>
          <div className="token-inputs">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
              secure
            />
          </div>

          <Button
            className="confirm-btn"
            variant="primary"
            // type="submit"
            onClick={submitOTPHandler}
          >
            Confirm
          </Button>
        </Form>
      </div>

      <div className="create-account">
        <Link to="/login">Back To Login</Link>
      </div>
    </SplashContent>
  )
}

export default TokenInput
