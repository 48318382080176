import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Col, Form, Row } from "react-bootstrap"
import InputField from "../../Layouts/InputField"
import { updateUser } from "../../Services/api"
import useUser from "../../Context/UserContext"
import { notifySuccess, notifyError } from "../Toast"
import { useHistory } from "react-router-dom"

const initialState = { alt: "", src: "" }

function EmployerProfile() {
  const history = useHistory()
  const [{ alt, src }, setPreview] = useState(initialState)
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { addUserDetails } = useUser()
  const { token, user } = globalState
  const { id, name, email, employer } = user

  const updateDetails = async payload => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const response = await updateUser(user.id, payload, headers)
      notifySuccess("Profile updated successfully")
      addUserDetails({
        user: response.data
      })
      history.push("/dashboard")
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      // email: email,
      name: name,
      company_logo: ""
    },
    // validationSchema: Yup.object({
    //   password_1: Yup.string()
    //     .max(15, "Must be 15 characters or less")
    //     .required("Password Required"),

    //   email: Yup.string()
    //     .email("Invalid email address")
    //     .required("Email Required")
    // }),
    onSubmit: values => {
      try {
        const formData = new FormData()
        for (let key in values) {
          if (key === "name") {
            formData.append(`${key}`, values[key])
          } else {
            formData.append(`employer.${key}`, values[key])
          }
        }
        updateDetails(formData)
      } catch (error) {
        if (error.response.status == 500) {
          notifyError("Internal server error")
        }
      }
    }
  })

  const fileHandler = event => {
    const { files } = event.target
    setPreview(
      files.length
        ? {
            src: URL.createObjectURL(files[0]),
            alt: files[0].name
          }
        : initialState
    )
    formik.setFieldValue("company_logo", files[0])
  }

  useEffect(() => {
    setPreview({
      src: employer.company_logo,
      alt: ""
    })
    // formik.setFieldValue("company_logo", employer.company_logo)
    formik.setFieldValue("name", user.name)
  }, [])

  return (
    <div className="container edit-my-profile" style={{ minHeight: "100vh" }}>
      <div className="title">
        <span>Edit Company Details</span>
      </div>
      <div className="box profile-card">
        <div className="img-wrapper">
          <input
            id="profileImage"
            accept="image/*"
            type="file"
            onChange={fileHandler}
          />

          <label for="profileImage">
            <span className="img-cover">
              {src ? (
                <img className="img-cover preview" src={src} alt={alt} />
              ) : (
                <i class="far fa-user"></i>
              )}
            </span>
            <div className="camera">
              <i class="far fa-camera"></i>
            </div>
          </label>
        </div>
        <div className="emp-details">
          <span className="name">{user.name}</span>
        </div>
      </div>
      <div className="edit-form">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col sx={12} sm={12} md={6}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <InputField
                  name="name"
                  type="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder="Your Name"
                  formik={formik}
                />
                <Form.Text className="text-danger">
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger">{formik.errors.name}</div>
                  ) : null}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Col>
            <Form.Group controlId="password_1" className="password_1-field">
              <Button
                className="update-btn"
                variant="primary"
                type="submit"
                onClick={values => {}}
                // disabled={formState}
              >
                {false ? <span>Loading...</span> : <span>Update</span>}
              </Button>
            </Form.Group>
          </Col>
        </Form>
      </div>
    </div>
  )
}

export default EmployerProfile
