import axios from "axios"

const axiosClient = axios.create()

axiosClient.defaults.baseURL = "https://tandem-30128.botics.co/api/v1"

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json"
}

//All request will wait 2 seconds before timeout
// axiosClient.defaults.timeout = 2000

// axiosClient.defaults.withCredentials = true

export function getRequest(URL, options) {
  return axiosClient
    .get(`/${URL}`, {
      ...options
    })
    .then(response => response)
}

export function postRequest(URL, payload, headers) {
  return axiosClient
    .post(`/${URL}`, payload, {
      headers: headers
    })
    .then(response => response)
}

export function getRequestWPayload(URL, token) {
  const config = {
    headers: {
      'Accept': "text/csv; charset=utf-8",
      Authorization: `Token ${token}`,
    }
  };
  return axiosClient.get(`/${URL}`, config)
}


export function patchRequest(URL, payload, headers) {
  return axiosClient
    .patch(`/${URL}`, payload, {
      headers: headers
    })
    .then(response => response)
}

export function deleteRequest(URL, headers, payload) {
  return axiosClient
    .delete(`/${URL}`, {
      headers: headers,
      data: payload
    })
    .then(response => response)
}

export function getRequestTNC(URL) {
  return axios.get(`${URL}`).then(response => response)
}
