import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Button, Col, Form, Row } from "react-bootstrap"
import Dropdown from "../../../Layouts/Dropdown"
import { updateUser } from "../../../Services/api"
import useUser from "../../../Context/UserContext"
import { notifyError, notifySuccess } from "../../Toast"
import { HOME_SIZE_OPTIONS } from "./data"
import { useHistory } from "react-router-dom"
import DropdownVirtualized from "../DropdownVirtualized"
import { Country } from "country-state-city"
import "./editAddress.scss"

function EditAddress() {
  const history = useHistory()
  const { addUserDetails } = useUser()
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { user, token } = globalState
  const [loading, setLoading] = useState(false)
  const [contrySelected, setCountrySelected] = useState({
    value: "",
    label: "Select"
  })
  const [contryCity, setCitySelected] = useState({
    value: "",
    label: "Select"
  })
  const [homeSize, setHomeSizeSelected] = useState({
    value: "",
    label: "Select"
  })

  const formik = useFormik({
    initialValues: {
      country: {
        value: "",
        label: "Select"
      },
      city: {
        value: "",
        label: "Select"
      },
      home_size: {
        value: "",
        label: "Select"
      },
      zip_code: "",
      street: "",
      internet_bill: "",
      electricity_bill: "",
      home_office_furniture_bill: "",
      home_office_usage: ""
    },
    validationSchema: Yup.object({
      country: Yup.object().test("country", "Please select country", val =>
        !val.value ? false : true
      ),
      city: Yup.object().test("city", "Please select City", val =>
        !val.value ? false : true
      ),
      home_size: Yup.object().test(
        "home_size",
        "Please select home size",
        val => (!val.value ? false : true)
      ),
      home_office_usage: Yup.string().test(
        "home_office_usage",
        "The percentage must be between 0 and 100",
        val => (val >= 0 && val <= 100 ? true : false)
      )
    }),
    onSubmit: async values => {
      const {
        country,
        city,
        home_size,
        zip_code,
        street,
        internet_bill,
        electricity_bill,
        home_office_furniture_bill,
        home_office_usage
      } = values
      const payload = {
        employee: {
          country: country.value,
          city: city.value,
          home_size: home_size.value,
          zip_code,
          street,
          internet_bill,
          electricity_bill,
          home_office_furniture_bill,
          home_office_usage
        }
      }

      try {
        const headers = {
          Authorization: `Token ${token}`
        }
        // console.log(payload)
        // return
        setLoading(true)
        const response = await updateUser(user.id, payload, headers)
        addUserDetails({
          user: response.data
        })
        setLoading(false)
        notifySuccess("User's address updated successfully")
        history.push("/address")
      } catch (error) {
        setLoading(false)
        if (error.response.status == 400) {
          notifyError(error.response.data[0])
        }
        if (error.response.status == 500) {
          notifyError("Internal server error")
        }
      }
    }
  })

  useEffect(() => {
    if (globalState && globalState.user && globalState.user.id) {
      if (user.employee) {
        const {
          country,
          city,
          home_size,
          zip_code,
          street,
          internet_bill,
          electricity_bill,
          home_office_furniture_bill,
          home_office_usage,
          new_home_office_usage
        } = user.employee
        formik.setFieldValue("country", {
          value: country,
          label: country
        })
        setCountrySelected({
          value: country,
          label: country
        })
        formik.setFieldValue("city", {
          value: city,
          label: city
        })
        setCitySelected({
          value: city,
          label: city
        })
        formik.setFieldValue("home_size", {
          value: home_size,
          label: home_size
        })
        setHomeSizeSelected({
          value: home_size,
          label: home_size
        })
        formik.setFieldValue("zip_code", zip_code)
        formik.setFieldValue("street", street)
        formik.setFieldValue("internet_bill", internet_bill)
        formik.setFieldValue("electricity_bill", electricity_bill)
        formik.setFieldValue(
          "home_office_furniture_bill",
          home_office_furniture_bill
        )
        formik.setFieldValue(
          "home_office_usage",
          home_office_usage || new_home_office_usage
        )
      }
    }
  }, [])
  return (
    <div className="container dashboard">
      <Row>
        <Col xs={12} sm={12} md={12} >
          <div style={{ marginBottom: 16, }}>
            <span className="title">Address</span>
          </div>
          <div className="edit-address-container">
            <div className="rows">
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="" className="mb">
                      <Form.Label>Country</Form.Label>
                      <Dropdown
                        name="country"
                        width="100%"
                        options={[
                          {
                            value: Country.getCountryByCode("US").name,
                            label: Country.getCountryByCode("US").name
                          }
                        ]}
                        placeholder="USA"
                        onChangeHandler={option => {
                          formik.setFieldValue("country", option)
                          setCountrySelected(option)
                        }}
                        onBlur={formik.handleBlur}
                        value={contrySelected}
                      />
                      <Form.Text className="text-danger">
                        {formik.touched.country && formik.errors.country ? (
                          <div className="text-danger">{formik.errors.country}</div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="" className="mb">
                      <Form.Label>City</Form.Label>

                      <DropdownVirtualized
                        name="city"
                        width="100%"
                        onChange={option => {
                          formik.setFieldValue("city", option)
                          setCitySelected(option)
                        }}
                        onBlur={formik.handleBlur}
                        value={contryCity}
                      />

                      <Form.Text className="text-danger">
                        {formik.touched.city && formik.errors.city ? (
                          <div className="text-danger">{formik.errors.city}</div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="" className="mb">
                      <Form.Label>Home Or Appartment Size</Form.Label>

                      <Dropdown
                        name="home_size"
                        width="100%"
                        options={HOME_SIZE_OPTIONS}
                        placeholder="2+ Bedrooms"
                        onChangeHandler={option => {
                          formik.setFieldValue("home_size", option)
                          setHomeSizeSelected(option)
                        }}
                        onBlur={formik.handleBlur}
                        value={homeSize}
                      />
                      <Form.Text className="text-danger">
                        {formik.touched.home_size && formik.errors.home_size ? (
                          <div className="text-danger">
                            {formik.errors.home_size}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="">
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        name="zip_code"
                        type="zip_code"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.zip_code}
                        placeholder="1010123"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="">
                      <Form.Label>Street/ Block/ Address</Form.Label>
                      <Form.Control
                        name="street"
                        type="street"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.street}
                        placeholder="1010123"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="">
                      <Form.Label>Home office usage</Form.Label>
                      <Form.Control
                        name="home_office_usage"
                        type="home_office_usage"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.home_office_usage}
                        placeholder="40%"
                      />
                      <Form.Text className="text-danger">
                        {formik.touched.home_office_usage && formik.errors.home_office_usage ? (
                          <div className="text-danger">{formik.errors.home_office_usage}</div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="line"></div>
                <Row>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="" className="mb">
                      <Form.Label>Internet Bill</Form.Label>
                      <Form.Control
                        name="internet_bill"
                        type="internet_bill"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.internet_bill}
                        placeholder="$0"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="" className="mb">
                      <Form.Label>Electricity Bill</Form.Label>
                      <Form.Control
                        name="electricity_bill"
                        type="electricity_bill"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.electricity_bill}
                        placeholder="$0"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6} lg={4}>
                    <Form.Group controlId="" className="mb">
                      <Form.Label>Home Office Furniture</Form.Label>
                      <Form.Control
                        name="home_office_furniture_bill"
                        type="home_office_furniture_bill"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.home_office_furniture_bill}
                        placeholder="$0"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="row-save-btn">
                  <Col className="col-save-btn">
                    <Button
                      className="save-btn"
                      variant="primary"
                      type="submit"
                      onClick={values => { }}
                      disabled={loading}
                    >
                      {loading ? <span>Updating...</span> : <span>Save</span>}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default EditAddress
