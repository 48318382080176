import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import useUser from "../../Context/UserContext"
import "./sidebar.scss"

const IconsWithTooltip = ({ active, label }) => (
  <OverlayTrigger
    key={"right"}
    placement={"right"}
    overlay={<Tooltip id={`tooltip-${"right"}`}>{label} </Tooltip>}
  >
    {active ? (
      <div className="active">
        <div className="icon"></div>
      </div>
    ) : (
      <div className="inactive">
        <div className="icon"></div>
      </div>
    )}
  </OverlayTrigger>
)

const navigations = [
  {
    label: "Dashboard",
    value: "dashboard",
    path: "/dashboard",
    permissions: ["Employer", "Employee"],
    render: active => (
      <div className="dashboard">
        <IconsWithTooltip active={active} label="Dashboard" />
      </div>
    )
  },

  {
    label: "Invite",
    value: "invite",
    path: "/invite",
    permissions: ["Employer"],
    render: active => (
      <div className="invite">
        <IconsWithTooltip active={active} label="Invite Employee" />
      </div>
    )
  },

  {
    label: "allowance",
    value: "allowance",
    path: "/allowances",
    permissions: ["Employer"],
    render: active => (
      <div className="allowance">
        <IconsWithTooltip active={active} label="Allowances" />
      </div>
    )
  },

  {
    label: "Profile",
    value: "profile",
    path: "/profile",
    permissions: ["Employee"],
    render: active => (
      <div className="profile">
        <IconsWithTooltip active={active} label="My Profile" />
      </div>
    )
  },
  {
    label: "Address",
    value: "address",
    path: "/address",
    permissions: ["Employee"],
    render: active => (
      <div className="address">
        <OverlayTrigger
          key={"right"}
          placement={"right"}
          overlay={<Tooltip id={`tooltip-${"right"}`}>{"My Address"} </Tooltip>}
        >
          {active ? (
            <div className="active">
              <i class="far fa-address-book"></i>
            </div>
          ) : (
            <div className="inactive">
              <i class="far fa-address-book"></i>
            </div>
          )}
        </OverlayTrigger>
      </div>
    )
  },
  {
    label: "Terms and Conditions",
    value: "terms-conditions",
    path: "/terms-conditions",
    permissions: ["Employee"],
    render: active => (
      <div className="terms-conditions">
        <OverlayTrigger
          key={"right"}
          placement={"right"}
          overlay={
            <Tooltip id={`tooltip-${"right"}`}>
              {"Terms and Conditions"}{" "}
            </Tooltip>
          }
        >
          {active ? (
            <div className="active">
              <i class="fal fa-file-check"></i>
            </div>
          ) : (
            <div className="inactive">
              <i class="fal fa-file-check"></i>
            </div>
          )}
        </OverlayTrigger>
      </div>
    )
  },
  {
    label: "settings",
    value: "settings",
    path: "/settings",
    permissions: ["Employer", "Employee"],
    render: active => (
      <div className="settings">
        <IconsWithTooltip active={active} label="Logout" />
      </div>
    )
  }
]

function Sidebar({ toggleSideNav }) {
  const history = useHistory()
  const { pathname } = history.location
  const {
    state: { user },
    userLogout
  } = useUser()
  return (
    <nav id="nav-wrapper">
      <div className="sidebar-wrapper">
        <Link to="/dashboard">
          <div className="brand"></div>
        </Link>
        {navigations.map(({ value, path, permissions, render }) => {
          if (!(permissions.indexOf(user.type) !== -1)) return null

          if (path === "/settings") {
            return (
              <div
                className={`${value}`}
                key={value}
                onClick={() => {
                  if (path === "/settings") {
                    userLogout()
                    history.push("/login")
                    return
                  }
                  history.push(path)
                }}
              >
                <div className="">{render(pathname.includes(path))}</div>
              </div>
            )
          }
          return (
            <div
              className={`icon-container ${value}`}
              key={value}
              onClick={() => {
                toggleSideNav()
                if (path === "/settings") {
                  userLogout()
                  history.push("/login")
                  return
                }
                history.push(path)
              }}
            >
              <div className="">{render(pathname.includes(path))}</div>
            </div>
          )
        })}
      </div>
    </nav>
  )
}

export default Sidebar
