import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Container, Row, Form } from "react-bootstrap"
import { Stepper, Step } from "react-form-stepper"
import useUser from "../../Context/UserContext"
import "./employeeSignup.scss"
import { useHistory, Link } from "react-router-dom"
import toast, { Toaster } from 'react-hot-toast';
import DatePicker from "../../Layouts/DatePicker"
import Dropdown from "../../Layouts/Dropdown/Dropdown"

const initialState = { alt: "", src: "" }

const EmployeeSignup = ({
  createUser,
  updateEmployeeDetails,
  addUserType,
  employers
}) => {
  const history = useHistory()
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const {
    token,
    user: { name, employee }
  } = globalState
  const { dob, phone, gender } = employee || {
    dob: "",
    phone: "",
    gender: ""
  }
  const [{ alt, src }, setPreview] = useState(initialState)
  const [startDate, setStartDate] = useState(null)
  const { state, addGoSteps, addUserDetails } = useUser()
  const { goSteps } = state
  const [type, setType] = useState({
    password: {
      type: "password"
    },
    confirmPassword: {
      type: "password"
    }
  })
  const [picture, setPicture] = useState(null)
  const [employerSelected, setEmployer] = useState({
    id: "",
    name: "Select"
  })
  const [departments, setDepartments] = useState([])
  const [roles, setRoles] = useState([])
  const [roleSelected, setRoleSelected] = useState({})
  const [departmentSelected, setDepartmentSelected] = useState({})
  const [isDisabled, setIsDisable] = useState(false)
  const [formState, setFormState] = useState(true)
  const [isImageAllowed, setIsImageAllowed] = useState(true)

  const [employeeDetails, setEmployeeDetails] = useState({
    name: "",
    department: "",
    role: "",
    picture: "",
    gender: "",
    phone: "",
    employee_id: "",
    country: "",
    city: "",
    zip_code: "",
    home_size: "",
    dob: "",
    street: "",
    website: "",
    employer: "",
    profile_completed: "True"
  })

  const [genderOptions, setGender] = useState([
    {
      label: "Male",
      value: "Male",
      checked: false
    },
    {
      label: "Female",
      value: "Female",
      checked: false
    },
    {
      label: "Non-binary",
      value: "Non-binary",
      checked: false
    },
    {
      label: "Do not wish to enter",
      value: "Do not wish to enter",
      checked: false
    }
  ])
  const fileHandler = event => {
    const { files } = event.target
    const isAllowed =
      allowedImageExtensions.indexOf(files[0].name.split(".")[1]) !== -1

    setIsImageAllowed(isAllowed)

    setPreview(
      files.length
        ? {
          src: URL.createObjectURL(files[0]),
          alt: files[0].name
        }
        : initialState
    )
    // picture(files[0])
    setEmployeeDetails(prevState => ({
      ...prevState,
      picture: files[0]
    }))
  }

  const handleEyeClick = name => {
    setType(prevType => {
      return {
        ...prevType,
        [name]:
          prevType[name].type === "password"
            ? {
              type: "text"
            }
            : {
              type: "password"
            }
      }
    })
  }
  // const [goSteps, addGoSteps] = useState(2)

  const onEmployerChange = value => {
    if (!value.id) {
      setEmployer(value)

      setIsDisable(true)
      formikEmployer.handleChange("employer")("")
      formikEmployer.handleChange("department")("")
      formikEmployer.handleChange("role")("")
      setDepartments([])
      setRoles([])
      setDepartmentSelected({ id: "", title: "Select" })
      setRoleSelected({ id: "", title: "Select" })

      return
    }
    setEmployer(value)
    formikEmployer.handleChange("employer")(value.id)
    const { departments } = value.employer || []
    setDepartments(departments)
    setDepartmentSelected({ id: "", title: "Select" })
    setRoleSelected({ id: "", title: "Select" })
    setRoles([])
    setIsDisable(false)
  }

  const onGenderChangeHandler = e => {
    const { value, checked } = e.target
    setGender(prevOptions => {
      return prevOptions.map(option => {
        if (option.value === value) {
          return { ...option, checked }
        } else {
          return { ...option, checked: false }
        }
      })
    })

    setEmployeeDetails(prevState => ({
      ...prevState,
      gender: value
    }))
    formikPersonal.setFieldValue("gender", value)
  }

  const formikLogin = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match")
    }),
    onSubmit: values => {
      addGoSteps({
        goSteps: 1
      })
      createUser(values)
    }
  })

  const formikPersonal = useFormik({
    initialValues: {
      name: "",
      phone: "",
      dob: "",
      gender: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name Required"),
      phone: Yup.string()
        .matches(/^[+\d][1-9][0-9]{5,14}/, {
          message: "Please enter a valid phone number",
          excludeEmptyString: false
        })
        .required("Please enter a valid phone number"),
      dob: Yup.string().required("Date of Birth Required"),
      gender: Yup.string().required("Gender Required")
    }),
    onSubmit: values => {
      setEmployeeDetails(prevState => ({
        ...prevState,
        phone: values.phone,
        name: values.name
      }))
      addGoSteps({
        goSteps: 2
      })
    }
  })

  const formikEmployer = useFormik({
    initialValues: {
      website: "",
      employer: "",
      department: "",
      employee_id: "",
      role: "",
      zipCode: ""
    },
    // validationSchema: Yup.object({
    //   website: Yup.string()
    //     .max(15, "Must be 15 characters or less")
    //     .required("Required"),
    //   department: Yup.string()
    //     .max(15, "Must be 15 characters or less")
    //     .required("Required"),
    //   role: Yup.string()
    //     .max(15, "Must be 15 characters or less")
    //     .required("Required"),
    //   epmployeeID: Yup.string()
    //     .max(15, "Must be 15 characters or less")
    //     .required("Required")
    // }),

    onSubmit: values => {
      const payload = {
        ...employeeDetails,
        employer: values.employer,
        role: values.role,
        department: values.department,
        employee_id: values.employee_id,
        website: values.website
      }
      const formData = new FormData()
      formData.append("name", payload.name)
      formData.append("profile_completed", "True")
      for (let key in payload) {
        if (payload[key] && (key !== "name" || key !== "profile_completed")) {
          formData.append(`employee.${key}`, payload[key])
        }
      }
      // formData.append(`employee.picture`, employeeDetails.employee.picture)
      if (formikEmployer.values.employee_id == "" && employerSelected.id != null) {
        toast.error("Please enter employee ID")
      } else {
        updateEmployeeDetails(formData)
      }
    }
  })

  useEffect(() => {
    if (!startDate) {
      formikPersonal.setFieldValue("dob", "")
    }
    // MM-DD-YYYY YYYY-MM-DD
    if (startDate) {
      const formattedDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`
      setEmployeeDetails(prevState => ({
        ...prevState,
        dob: formattedDate
      }))
      formikPersonal.setFieldValue("dob", formattedDate)
    }
  }, [startDate])

  useEffect(() => {
    if (globalState && globalState.user && globalState.user.employee) {
      const {
        name,
        employee: { department, role, employer, employee_id }
      } = globalState.user
      setEmployer(employer)
      setDepartmentSelected(department)
      setRoleSelected(role)

      if (employer) {
        formikEmployer.setFieldValue("employer", employer.id)
      }
      if (department) {
        formikEmployer.setFieldValue("department", department.id)
      }
      if (role) {
        formikEmployer.setFieldValue("role", role.id)
      }
      if (employee_id) {
        formikEmployer.setFieldValue("employee_id", employee_id)
      }
      if (name) {
        formikPersonal.setFieldValue("name", name)
      }
    }
  }, [JSON.stringify(globalState.user)])

  const backToLogin = () => {
    localStorage.clear()
    addUserDetails({})
    history.push("/login")
  }

  return (
    <div className={`stepper-${goSteps}`}>
      <Toaster />
      <Stepper activeStep={goSteps}>
        <Step
          onClick={() => {
            if (!token) {
              addGoSteps({
                goSteps: 0
              })
            }
          }}
        />
        <Step
          onClick={() =>
            addGoSteps({
              goSteps: 1
            })
          }
        />
        <Step
          onClick={() =>
            addGoSteps({
              goSteps: 2
            })
          }
        />
      </Stepper>
      {goSteps === 0 && (
        <div>
          <div className="company-details"> Login Details</div>
          <div className="login-form">
            <Form onSubmit={formikLogin.handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label> Email ID</Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  onChange={formikLogin.handleChange}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.email}
                  placeholder="maile@company.com"
                />
                <Form.Text className="text-danger">
                  {formikLogin.touched.email && formikLogin.errors.email ? (
                    <div className="text-danger">
                      {formikLogin.errors.email}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="password" className="password-field">
                <Form.Label className="lable-password">Password</Form.Label>
                <Form.Control
                  name="password"
                  type={type["password"].type}
                  onChange={formikLogin.handleChange}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.password}
                  placeholder="Password"
                />
                <Form.Text className="text-danger">
                  {formikLogin.touched.password &&
                    formikLogin.errors.password ? (
                    <div className="text-danger">
                      {formikLogin.errors.password}
                    </div>
                  ) : null}
                </Form.Text>
                <span
                  className="eye-icon"
                  onClick={() => handleEyeClick("password")}
                >
                  {type["password"].type === "text" ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </span>
              </Form.Group>
              <Form.Group
                controlId="confirmPassword"
                className="confirm-password-field"
              >
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  name="confirmPassword"
                  type={type["confirmPassword"].type}
                  onChange={formikLogin.handleChange}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.confirmPassword}
                  placeholder="Confirm Password"
                />
                <Form.Text className="text-danger">
                  {formikLogin.touched.confirmPassword &&
                    formikLogin.errors.confirmPassword ? (
                    <div className="text-danger">
                      {formikLogin.errors.confirmPassword}
                    </div>
                  ) : null}
                </Form.Text>

                <span
                  className="eye-icon"
                  onClick={() => handleEyeClick("confirmPassword")}
                >
                  {type["confirmPassword"].type === "text" ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </span>
              </Form.Group>
              <Button
                className="signIn-btn"
                variant="primary"
                type="submit"
                onClick={values => setFormState(values)}
              >
                Continue
              </Button>
            </Form>
            <div className="create-account">
              <Link to="" onClick={backToLogin}>
                Back To Login
              </Link>
            </div>
          </div>
        </div>
      )}
      {goSteps === 1 && (
        <div>
          <div className="company-details"> Personal Details</div>
          <div className="fileUpload-wrapper">
            <span className="img-cover">
              {src ? (
                <img
                  className={`img-cover preview ${isImageAllowed ? "" : "image-preview-error"
                    }`}
                  src={src}
                  alt={alt}
                />
              ) : (
                <div className="logo"></div>
              )}
            </span>
            <div>
              <label className="btn fileUpload btn-default">
                <span>
                  Browse <input type="file" hidden="" onChange={fileHandler} />
                </span>
              </label>
              <p className={`${isImageAllowed ? "" : "image-upload-error"}`}>
                {" "}
                Please upload image with Jpg, Jpeg, Png or gif, etc...
              </p>
            </div>
          </div>
          <div className="login-form">
            <Form onSubmit={formikPersonal.handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="name"
                  onChange={formikPersonal.handleChange}
                  onBlur={formikPersonal.handleBlur}
                  value={formikPersonal.values.name}
                  placeholder="Your name"
                />
                <Form.Text className="text-danger">
                  {formikPersonal.touched.name && formikPersonal.errors.name ? (
                    <div className="text-danger">
                      {formikPersonal.errors.name}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name="phone"
                  type="phone"
                  onChange={formikPersonal.handleChange}
                  onBlur={formikPersonal.handleBlur}
                  value={formikPersonal.values["phone"]}
                  placeholder="Your phone"
                />
                <Form.Text className="text-danger">
                  {formikPersonal.touched["phone"] &&
                    formikPersonal.errors["phone"] ? (
                    <div className="text-danger">
                      {formikPersonal.errors["phone"]}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="dob">
                <Form.Label>Date of Birth</Form.Label>
                <DatePicker
                  startDate={startDate}
                  setStartDate={dob => {
                    setStartDate(dob)
                  }}
                />
                <Form.Text className="text-danger">
                  {formikPersonal.touched.dob && formikPersonal.errors.dob ? (
                    <div className="text-danger">
                      {formikPersonal.errors.dob}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="gender">
                <Form.Label>Gender</Form.Label>
                <div key={`inline-${type}`} className="mb-3">
                  {genderOptions.map(({ label, value, checked }) => (
                    <Form.Check
                      inline
                      label={label}
                      name={label}
                      type="radio"
                      id={`inline-${label}-1`}
                      onChange={onGenderChangeHandler}
                      value={value}
                      checked={checked}
                    />
                  ))}
                </div>
                <Form.Text className="text-danger">
                  {formikPersonal.touched.gender &&
                    formikPersonal.errors.gender ? (
                    <div className="text-danger">
                      {formikPersonal.errors.gender}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Button
                className="signIn-btn"
                variant="primary"
                type="submit"
                onClick={values => setFormState(values)}
                disabled={!isImageAllowed}
              >
                Continue
              </Button>
            </Form>
            <div className="create-account">
              <Link to="" onClick={backToLogin}>
                Back To Login
              </Link>
            </div>
          </div>
        </div>
      )}

      {goSteps === 2 && (
        <div>
          <div className="company-details">Employer Details</div>
          <div className="login-form">
            <Form onSubmit={formikEmployer.handleSubmit}>
              <Form.Group controlId="employer">
                <Form.Label>Company Name</Form.Label>
                <Dropdown
                  name="employer"
                  width="100%"
                  options={employers}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder="Select"
                  onChangeHandler={onEmployerChange}
                  value={employerSelected}
                />
                <Form.Text className="text-danger">
                  {formikEmployer.touched.employer &&
                    formikEmployer.errors.employer ? (
                    <div className="text-danger">
                      {formikEmployer.errors.employer}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="department">
                <Form.Label>Department</Form.Label>
                <Dropdown
                  name="department"
                  width="100%"
                  options={departments}
                  getOptionLabel={option => option.title}
                  getOptionValue={option => option.id}
                  placeholder="Select"
                  onChangeHandler={value => {
                    formikEmployer.handleChange("department")(value.id)
                    setDepartmentSelected(value)
                    setRoles([...value.roles])
                    setRoleSelected({ id: "", title: "Select" })
                  }}
                  value={departmentSelected}
                  isDisabled={isDisabled}
                  components={
                    isDisabled
                      ? {
                        Menu: () => null,
                        MenuList: () => null,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }
                      : null
                  }
                />
                <Form.Text className="text-danger">
                  {formikEmployer.touched.department &&
                    formikEmployer.errors.department ? (
                    <div className="text-danger">
                      {formikEmployer.errors.department}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="role">
                <Form.Label>Role</Form.Label>
                <Dropdown
                  name="role"
                  width="100%"
                  options={roles}
                  getOptionLabel={option => option.title}
                  getOptionValue={option => option.id}
                  placeholder="Select"
                  onChangeHandler={value => {
                    formikEmployer.handleChange("role")(value.id)
                    setRoleSelected(value)
                  }}
                  value={roleSelected}
                  isDisabled={isDisabled}
                  components={
                    isDisabled
                      ? {
                        Menu: () => null,
                        MenuList: () => null,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }
                      : null
                  }
                />
                <Form.Text className="text-danger">
                  {formikEmployer.touched.role && formikEmployer.errors.role ? (
                    <div className="text-danger">
                      {formikEmployer.errors.role}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="epmployeeID">
                <Form.Label>Employee ID/Number</Form.Label>
                <Form.Control
                  name="employee_id"
                  type="employee_id"
                  onChange={formikEmployer.handleChange}
                  onBlur={formikEmployer.handleBlur}
                  value={isDisabled ? "" : formikEmployer.values.employee_id}
                  placeholder="eg, CA XXXXX"
                  disabled={isDisabled}
                />
                <Form.Text className="text-danger">
                  {formikEmployer.touched.employee_id &&
                    formikEmployer.errors.employee_id ? (
                    <div className="text-danger">
                      {formikEmployer.errors.employee_id}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Button
                className="signIn-btn"
                variant="primary"
                type="submit"
                onClick={values => setFormState(values)}
              >
                Update
              </Button>
            </Form>

            <div className="create-account">
              <Link to="" onClick={backToLogin}>
                Back To Login
              </Link>
            </div>
          </div>
        </div>
      )}
      {goSteps === 3 && (
        <div className="stepper-success">
          <div className="success-page login-form">
            <div className="logo">
              <i className="fas fa-check-circle"></i>
            </div>
            <div className="title">Success</div>
            <div className="message">
              You have been registered Successfully. Please proceed to login.
            </div>
            <Button
              className="signIn-btn"
              variant="primary"
              onClick={() => {
                localStorage.clear()
                addGoSteps({
                  goSteps: 0
                })
                history.push("/login")
              }}
            >
              Login
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default EmployeeSignup

const allowedImageExtensions = [
  "bmp",
  "dib",
  "gif",
  "tif",
  "tiff",
  "jfif",
  "jpe",
  "jpg",
  "jpeg",
  "pbm",
  "pgm",
  "ppm",
  "pnm",
  "png",
  "apng",
  "blp",
  "bufr",
  "cur",
  "pcx",
  "dcx",
  "dds",
  "ps",
  "eps",
  "fit",
  "fits",
  "fli",
  "flc",
  "ftc",
  "ftu",
  "gbr",
  "grib",
  "h5",
  "hdf",
  "jp2",
  "j2k",
  "jpc",
  "jpf",
  "jpx",
  "j2c",
  "icns",
  "ico",
  "im",
  "iim",
  "mpg",
  "mpeg",
  "mpo",
  "msp",
  "palm",
  "pcd",
  "pdf",
  "pxr",
  "psd",
  "bw",
  "rgb",
  "rgba",
  "sgi",
  "ras",
  "tga",
  "icb",
  "vda",
  "vst",
  "webp",
  "wmf",
  "emf",
  "xbm",
  "xpm"
]
