import React, { useEffect } from "react"

import { createContext, useReducer, useContext } from "react"
import userReducer, { initialState } from "./userReducer"
import {
  saveToLocalStorage,
  loadFromLocalStorage
} from "../utils/localStorageHelper"

const UserContext = createContext(initialState)

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState)

  const addUserDetails = payload => {
    dispatch({
      type: "ADD_USER_DETAILS",
      payload: {
        ...payload
      }
    })
  }

  const addUserType = payload => {
    dispatch({
      type: "ADD_USER_TYPE",
      payload: {
        ...payload
      }
    })
  }

  const addCompanyDetails = payload => {
    dispatch({
      type: "ADD_COMPANY_DETAILS",
      payload: {
        ...payload
      }
    })
  }

  const addGoSteps = payload => {
    dispatch({
      type: "ADD_GO_STEPS",
      payload: {
        ...payload
      }
    })
  }

  const userLogout = payload => {
    dispatch({
      type: "USER_LOGOUT"
    })
  }

  const setLoader = payload => {
    dispatch({
      type: "SET_LOADER",
      payload: {
        ...payload
      }
    })
  }

  const setDepartmentToGlobalState = payload => {
    dispatch({
      type: "SET_DEPARTMENT",
      payload: {
        ...payload
      }
    })
  }

  const value = {
    // total: state.total,
    // products: state.products,
    state,
    addUserType,
    addCompanyDetails,
    addUserDetails,
    addGoSteps,
    userLogout,
    setLoader,
    setDepartmentToGlobalState
  }

  const restoreGlobalState = prevGlobalState => {
    addUserDetails(prevGlobalState)
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      saveToLocalStorage("GLOBAL_STATE", state)
    }

    const prevGlobalState = loadFromLocalStorage("GLOBAL_STATE")
    if (!prevGlobalState) {
      saveToLocalStorage("GLOBAL_STATE", state)
    }

    if (prevGlobalState) {
      restoreGlobalState(prevGlobalState)
    }
    return () => {
      window.onbeforeunload = null
      saveToLocalStorage("GLOBAL_STATE")
    }
  }, [state])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error("useUser must be used within UserContext")
  }

  return context
}

export default useUser
