import React, { useState } from "react"
import * as Yup from "yup"
import { Button, Form, Modal } from "react-bootstrap"
import AddIcon from "../../Assets/images/add_icon.png"
import ModalComponent from "../ModalComponent"
import BasicTable from "../Table/BasicTable"
import FileUpload from "../../Assets/images/file_upload.png"
import { useFormik } from "formik"

function AllowanceType({ onClickAddHandler, data }) {
  const [show, setShow] = useState(false)
  const formik = useFormik({
    initialValues: {
      title: ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Name is Required.")
    }),
    onSubmit: values => {
      onClickAddHandler(values, () => {
        formik.setFieldValue("title", "")
        setShow(false)
      })
    }
  })

  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "psuedoId"
      },
      {
        Header: "Allowance type",
        accessor: "title"
      }
    ],
    []
  )

  const prepareData = React.useMemo(
    () => data.map((d, index) => ({ ...d, psuedoId: index + 1 })),
    [JSON.stringify(data)]
  )

  return (
    <div className="allowance-card column">
      <div className="header">
        <span>Allowances</span>
        <Button className="add-icon" onClick={() => setShow(true)}>
          <img src={AddIcon} />
        </Button>
      </div>
      <div className="body">
        <div className="users-table">
          <BasicTable columns={columns} data={prepareData} />
        </div>
      </div>
      <div className="mng-allowance-modal">
        <ModalComponent
          className="manage-allowance"
          btnText="Save"
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Allowance Type
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body className="show-grid">
              <div className="add-wrapper">
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="title"
                    type="title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    placeholder="Eg, Internet"
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.title && formik.errors.title ? (
                      <div className="text-danger">{formik.errors.title}</div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="signIn-btn"
                variant="primary"
                type="submit"
                onClick={values => {}}
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </ModalComponent>
      </div>
    </div>
  )
}

export default AllowanceType
