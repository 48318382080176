import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Row, Col, Button, Form } from "react-bootstrap"
import DatePicker from "../../../Layouts/DatePicker"
import BasicTable from "../../Table/BasicTable"
import { getEmployeeBilledAllowances } from "../../../Services/api"
import "./assignedAllowances.scss"
import { getCurrentMonthDateRange } from "../../../utils/localStorageHelper"

function AssignedAllowances() {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { token, user } = globalState
  const [data, setData] = useState([])
  const { startDate: s, endDate: e } = getCurrentMonthDateRange()
  const [startDate, setStartDate] = useState(s)
  const [endDate, setEndDate] = useState(e)
  const [loading, setLoading] = useState(false)

  const dateFormatter = date => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  useEffect(() => {
    formik.setFieldValue("start_date", dateFormatter(startDate))
  }, [startDate])

  useEffect(() => {
    formik.setFieldValue("end_date", dateFormatter(endDate))
  }, [endDate])

  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: ""
    },
    validationSchema: Yup.object({
      // start_date: Yup.date().default(() => new Date()),
      // end_date: Yup.date().when("start_date", (start_date, schema) => {
      //   return start_date && schema.min(start_date)
      // })
    }),
    onSubmit: async values => {
      const { start_date, end_date } = values
      const query = `start_date=${start_date}&end_date=${end_date}`
      fetchAllowances(query)
    }
  })

  const fetchAllowances = async query => {
    const config = {
      headers: { Authorization: `Token ${token}` }
    }
    setLoading(true)
    const response = await getEmployeeBilledAllowances(query, config)
    const allowances = response.data || []

    const tableData = allowances.map((allowance, index) => {
      const { allowance__frequency, allowance__type__title, total_sales } =
        allowance

      /** Checkin if we have HRA  */
      const homeAllowance = user?.employee?.home_allowance
      if (allowances.length == 0) {
        return {
          psuedoId: `${index + 1}`,
          frequency: "Monthly",
          type: "Home Rent Allowance",
          price: homeAllowance
        }
      }

      return {
        psuedoId: `${index + 1}`,
        frequency: allowance__frequency,
        type: allowance__type__title,
        price: total_sales
      }
    })
    setLoading(false)
    setData(tableData)
  }

  useEffect(() => {
    const { start_date, end_date } = getCurrentMonthDateRange()
    const query = `start_date=${start_date}&end_date=${end_date}`
    fetchAllowances(query)
  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "psuedoId"
      },
      {
        Header: "Allowances",
        accessor: "type"
      },
      {
        Header: "Frequency",
        accessor: "frequency"
      },
      {
        Header: "Amount(USD)",
        accessor: "price"
      }
    ],
    []
  )

  return (
    <div className="container dashboard">
      <Row>
        <Col xs={12} sm={12} md={12} >
          <div className="">
            <div className="title">
              <span>Allowances Allocation</span>
            </div>
            <div className="table-container">
              <Form onSubmit={formik.handleSubmit}>
                <Row className="filters">
                  <Col lg={4} md={12} sm={12}>
                    <div className="from">
                      <Form.Group controlId="start_date" className="start_date">
                        <DatePicker
                          startDate={startDate}
                          setStartDate={setStartDate}
                        />
                        <Form.Text className="text-danger">
                          {formik.touched.start_date &&
                            formik.errors.start_date ? (
                            <div className="text-danger">
                              {formik.errors.start_date}
                            </div>
                          ) : null}
                        </Form.Text>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={4} md={12} sm={12}>
                    <div className="to">
                      <Form.Group controlId="end_date" className="end_date">
                        <DatePicker
                          startDate={endDate}
                          setStartDate={setEndDate}
                        />

                        <Form.Text className="text-danger">
                          {formik.touched.end_date && formik.errors.end_date ? (
                            <div className="text-danger">
                              {formik.errors.end_date}
                            </div>
                          ) : null}
                        </Form.Text>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={2} md={1} sm={12}></Col>
                  <Col lg={2} md={12} sm={12}>
                    <div className="go-btn">
                      <Button
                        className="signIn-btn"
                        variant="primary"
                        type="submit"
                        onClick={values => { }}
                      >
                        Go
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
              <Row className="table-row">
                <Col>
                  <div className="users-table">
                    <p>Users</p>
                    <BasicTable
                      columns={columns}
                      data={data}
                      loading={loading}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AssignedAllowances
