import React, { useEffect, useState } from "react"
import AssignAllowance from "./AssignAllowance"
import ManageAllowance from "./ManageAllowances"
import useUser from "../../Context/UserContext"
import {
  getDepartment,
  getAllowanceType,
  getAllowances
} from "../../Services/api"
import "./manageAllowance.scss"

function Allowances() {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { token, user } = globalState
  const { setDepartmentToGlobalState } = useUser()
  const [departments, setDepartments] = useState([])
  const [roles, setRoles] = useState([])
  const [allowanceType, setAllowanceType] = useState([])
  const [departmentSelected, setDepartment] = useState(null)
  const [allowancesData, setAllowancesData] = useState([])
  const [total, setTotal] = useState({})
  const [employeesUnderAllowance, setEmployeesUnderAllowance] = useState([])
  const [assign, setAssign] = useState(false)

  const fetchDepartments = async () => {
    try {
      const config = {
        headers: { Authorization: `Token ${token}` },
        params: {
          employer: user.id
        }
      }
      const response = await getDepartment(config)
      setDepartments(response.data)
      if (!departmentSelected) {
        setDepartmentToGlobalState({
          departmentSelected: response.data[0]
        })
        setDepartment(response.data[0])
      }
    } catch (error) {}
  }

  const setRolesTableData = () => {
    if (!departmentSelected) return

    const filterDept = departments.find(
      ({ id }) => departmentSelected.id === id
    )
    if (filterDept && filterDept.roles) {
      setRoles(filterDept.roles)
    }
  }

  const onClickRowHandler = row => {
    setDepartment(row)
    setDepartmentToGlobalState({
      departmentSelected: row
    })
  }

  const fetchAllowanceType = async () => {
    try {
      const config = {
        headers: { Authorization: `Token ${token}` }
      }
      const response = await getAllowanceType(config)
      setAllowanceType(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllowances = async () => {
    try {
      const config = {
        headers: { Authorization: `Token ${token}` }
      }
      const response = await getAllowances(config)
      setAllowancesData(response.data.allowances)
      setTotal(response.data.total)
    } catch (error) {}
  }

  useEffect(() => {
    fetchAllowanceType()
    fetchDepartments()
    fetchAllowances()
    return () => {
      setDepartmentToGlobalState({
        departmentSelected: null
      })
    }
  }, [])

  useEffect(() => {
    if (departments.length) {
      setRolesTableData()
    }
  }, [departments, departmentSelected])

  return (
    <>
      {assign ? (
        <AssignAllowance
          departments={departments}
          roles={roles}
          allowanceType={allowanceType}
          allowancesData={allowancesData}
          total={total}
          setAssign={setAssign}
          fetchAllowances={fetchAllowances}
        />
      ) : (
        <ManageAllowance
          departments={departments}
          roles={roles}
          allowanceType={allowanceType}
          fetchAllowanceType={fetchAllowanceType}
          onClickRowHandler={onClickRowHandler}
          setAssign={setAssign}
          fetchDepartments={fetchDepartments}
        />
      )}
    </>
  )
}

export default Allowances
