import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import ModalComponent from "../ModalComponent"
import { useFormik } from "formik"
import Download from "../../Assets/images/download.png"
import Dropdown from "../../Layouts/Dropdown"
import {
  approveEmployee,
  cancelEmployee,
  getDepartment,
  releaseEmployee,
  reomoveAttachment,
  updateUser,
  approveAddressChange,
  updateHomeAllowance,
  getUser,
  getchangeEmployerRequest
} from "../../Services/api"
import { useHistory, useLocation } from "react-router-dom"
import { notifySuccess, notifyError } from "../../Components/Toast"
import ApprovedHRACard from "./ApprovedHRACard"
import EmployeeProfileCard from "./EmployeeProfileCard"
import AttachmentsCard from "./AttachmentsCard"
import AssignedAllowancesCard from "./AssignedAllowancesCard"
import "./employeeDetails.scss"

function EmployeeDetails({ data }) {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { user, token } = globalState
  const location = useLocation()
  const { userId } = location.state
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [employeesRequest, setEmployeesRequest] = useState([])
  const [departmentOptions, setDepartments] = useState([])
  const [roles, setRoles] = useState([])
  const [show, setShow] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [files, setFiles] = useState([])
  const [profile, setProfile] = useState({
    email: "",
    name: "",
    picture: "",
    role: "",
    department: "",
    allowances: [],
    attachments: [],
    total_allowance: "",
    gender: "",
    employee_id: "",
    phone: "",
    dob: "",
    country: "",
    city: "",
    street: "",
    zip_code: "",
    home_size: "",
    approved: "",
    showApproveBtn: "",
    id: "",
    approvalId: ""
  })

  const {
    name,
    email,
    picture,
    role,
    department,
    allowances,
    attachments,
    total_allowance,
    gender,
    employee_id,
    phone,
    dob,
    country,
    city,
    street,
    zip_code,
    home_size,
    home_allowance,
    approved,
    employee_change_requested,
    showApproveBtn,
    id,
    address_change_requested,
    new_country,
    new_city,
    new_street,
    new_zip_code,
    new_home_size,
    internet_bill,
    electricity_bill,
    home_office_furniture_bill,
    home_office_usage,
    new_home_office_usage,
    new_home_allowance,
    original_home_allowance,
    new_original_home_allowance
  } = profile
  const [departmentSelected, setDepartment] = useState({
    id: "",
    title: "Select"
  })
  const [roleSelected, setRole] = useState({
    id: "",
    title: "Select"
  })

  const updateStateAndLocalStorage = response => {
    const { email, employee, id, name, profile_completed, type } = response.data

    setProfile({
      email,
      id,
      name,
      profile_completed,
      type,
      ...employee
    })
  }

  const formik = useFormik({
    initialValues: {
      employee_id: employee_id,
      name: name,
      role: role?.id ? role?.id : "",
      department: department?.id ? department?.id : ""
    },
    validationSchema: Yup.object({
      // employee_id: Yup.string().required("name Required"),
      // name: Yup.string().required("name Required")
    }),
    onSubmit: async values => {
      const payload = {
        name: values.name,
        employee: {
          ...values
        }
      }
      try {
        const headers = {
          Authorization: `Token ${token}`
        }

        const response = await updateUser(profile?.id, payload, headers)
        updateStateAndLocalStorage(response)
        setShow(false)
        notifySuccess("User updated successfully")
      } catch (error) {
        if (error.response.status == 500) {
          notifyError("Internal server error")
        }
      }
    }
  })

  const fetchDepartments = async () => {
    try {
      const config = {
        headers: { Authorization: `Token ${token}` },
        params: {
          employer: user.id
        }
      }
      const response = await getDepartment(config)
      setDepartments(response.data)
    } catch (error) { }
  }

  const fetchchangeEmployerRequest = async () => {
    const headers = { Authorization: `Token ${token}` }
    try {
      const response = await getchangeEmployerRequest({ headers })
      setEmployeesRequest(response.data)
    } catch (error) { }
  }

  const setRolesTableData = () => {
    const filterDept = departmentOptions.find(
      ({ id }) => departmentSelected.id === id
    )
    if (filterDept && filterDept.roles) {
      setRoles(filterDept.roles)
    }
  }

  const approveEmployeeHandle = async () => {
    try {
      const employeesRequestSelected = employeesRequest.filter(
        empReq => empReq?.employee?.id === id
      )
      const approvalId = employeesRequestSelected?.[0]?.id
      const headers = { Authorization: `Token ${token}` }
      const payload = {
        approved: "True"
      }
      const response = await approveEmployee(approvalId, payload, headers)
      setIsApproved(true)
      notifySuccess(response.data.detail)
    } catch (error) {
      notifyError(error.response.detail)
    }
  }

  const cancelEmployeeHandle = async () => {
    try {
      const headers = { Authorization: `Token ${token}` }
      const payload = {}
      const response = await cancelEmployee(
        profile.approvalId,
        payload,
        headers
      )
      notifySuccess(response.data.detail)
      history.push("/invite")
    } catch (error) {
      notifyError(error.response.detail)
    }
  }

  const onFileChangeHandle = e => {
    const { files } = e.target
    setFiles(Array.from(files))
  }

  const onClickUploadHandle = async () => {
    if (!files.length) {
      return
    }

    const formData = new FormData()
    files.forEach((file, index) => {
      formData.append(`employee.attachments[${index}]file`, file)
    })

    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      setLoading(true)
      const response = await updateUser(profile.id, formData, headers)
      updateStateAndLocalStorage(response)
      setLoading(false)
      setFiles([])

      notifySuccess("Files uploaded successfully")
    } catch (error) { }
  }

  const romoveAttachmentHandle = async id => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const payload = {
        attachment: id
      }
      await reomoveAttachment(payload, headers)
      fetchUser()
      setFiles([])
      notifySuccess("Attachment deleted successfully")
    } catch (error) {
      if (error.response.status == 500) {
        notifyError("Internal server error")
      }
    }
  }

  const getAttachmentList = () => {
    if (!Array.isArray(attachments)) {
      return null
    }
    return attachments.map(({ id, file }) => {
      let fileName
      if (file) {
        let arr = file.split("/")
        fileName = arr[arr.length - 1]
        return (
          <div className="download" key={id}>
            <span style={{}} id="abcdefd">{fileName.length > 45 ? `${fileName.slice(0, 45)}...` : fileName}</span>
            <a href={file} target="_blank" download>
              <img src={Download} />
            </a>
            <Button style={{}} onClick={() => romoveAttachmentHandle(id)}>
              <i class="far fa-times"></i>
            </Button>
          </div>
        )
      }
    })
  }

  const releaseEmployeeHandle = async () => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const payload = {
        employee_id: id
      }
      const response = await releaseEmployee(payload, headers)
      notifySuccess("Employee released successfully")
      history.push("/invite")
    } catch (error) {
      if (error.response.status == 500) {
        notifyError("Internal server error")
      }
    }
  }

  const onClickApprove = async values => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const payload = {
        employee_id: id,
        approved: "True",
        ...values
      }
      const response = await approveAddressChange(payload, headers)
      updateStateAndLocalStorage(response)
      notifySuccess("HRA approved successfully")
    } catch (error) {
      console.log(error)
      if (error.response == 500) {
        notifyError("Internal server error")
      }
      notifyError(JSON.stringify(error))
    }
  }

  const formikHRA = useFormik({
    initialValues: {
      allowance: ""
    },
    validationSchema: Yup.object({
      // employee_id: Yup.string().required("name Required"),
    }),
    onSubmit: async values => {
      const payload = {
        ...values,
        employee_id: profile?.id
      }
      // console.log(payload)
      try {
        const headers = {
          Authorization: `Token ${token}`
        }

        if (address_change_requested) {
          return onClickApprove(values)
        }

        const response = await updateHomeAllowance(payload, headers)
        updateStateAndLocalStorage(response)
        notifySuccess("User updated successfully")
      } catch (error) {
        if (error.response.status == 500) {
          notifyError("Internal server error")
        }
        notifyError(JSON.stringify(error))
      }
    }
  })

  const fetchUser = () => {
    const config = {
      headers: { Authorization: `Token ${token}` }
    }
    // const userId = userData?.user?.id
    getUser(userId, config)
      .then(response => {
        // console.log(response)
        setProfile({
          ...response.data,
          ...response.data.employee
        })
      })
      .catch(error =>
        console.log(error)
      )
  }

  useEffect(() => {
    formik.setFieldValue("home_allowance", home_allowance)
  }, [home_allowance])

  useEffect(() => {
    if (department?.id) {
      setDepartment(department)
      formik.setFieldValue("department", department.id)
    }
    if (role?.id) {
      setRole(role)
      formik.setFieldValue("role", role?.id)
    }
  }, [department?.id])

  // useEffect(() => {
  //   fetchDepartments()
  // }, [JSON.stringify(profile)])

  useEffect(() => {
    if (departmentOptions.length) {
      setRolesTableData()
    }
  }, [departmentOptions, departmentSelected])

  useEffect(() => {
    fetchDepartments()
    fetchchangeEmployerRequest()
    fetchUser()
  }, [userId])

  return (
    <div className="dashboard container">
      <div className="container employee-profile">
        <div className="title-wrapper">
          <span className="text">Employee Profile</span>
          {!isApproved && employee_change_requested ? (
            <div className="buttons">
              <Button className="release-btn">
                <span>Release Employee</span>
              </Button>
              <Button className="cancel-btn" onClick={cancelEmployeeHandle}>
                <span>Cancel</span>
              </Button>
              <Button className="approve-btn" onClick={approveEmployeeHandle}>
                <span>Approve</span>
              </Button>
            </div>
          ) : (
            <Button className="release-btn" onClick={releaseEmployeeHandle}>
              <span>Release Employee</span>
            </Button>
          )}
        </div>
        <div class="row">
          <Col sm={12} md={12} xl={4}>
            <EmployeeProfileCard
              employee_id={employee_id}
              picture={picture}
              name={name}
              gender={gender}
              role={role}
              department={department}
              email={email}
              phone={phone}
              dob={dob}
              isApproved={isApproved}
              showApproveBtn={showApproveBtn}
              formik={formik}
              setShow={setShow}
            />
          </Col>

          <Col sm={12} md={12} xl={4}>
            <AttachmentsCard
              getAttachmentList={getAttachmentList}
              onFileChangeHandle={onFileChangeHandle}
              files={files}
              onClickUploadHandle={onClickUploadHandle}
              loading={loading}
            />
          </Col>
          <Col sm={12} md={12} xl={4}>
            <ApprovedHRACard
              title="Address"
              street={street}
              city={city}
              country={country}
              zip_code={zip_code}
              home_size={home_size}
              original_home_allowance={original_home_allowance}
              assigned_home_allowance={home_allowance}
              home_office_usage={home_office_usage}
              electricity_bill={electricity_bill}
              internet_bill={internet_bill}
              home_office_furniture_bill={home_office_furniture_bill}
              formikHRA={formikHRA}
            />
          </Col>
          <Col sm={12} md={12} xl={4}>
            <AssignedAllowancesCard
              allowances={allowances || []}
              total_allowance={total_allowance}
            />
          </Col>
          <Col sm={12} md={12} xl={4}>
            {address_change_requested && (
              <ApprovedHRACard
                title="New Address"
                street={new_street}
                city={new_city}
                country={new_country}
                zip_code={new_zip_code}
                home_size={new_home_size}
                original_home_allowance={new_original_home_allowance}
                home_allowance={new_original_home_allowance}
                home_office_usage={new_home_office_usage}
                electricity_bill={electricity_bill}
                internet_bill={internet_bill}
                home_office_furniture_bill={home_office_furniture_bill}
                formikHRA={formikHRA}
                address_change_requested={address_change_requested}
              />
            )}
          </Col>
        </div>
        <ModalComponent
          className="edit-profile-modal"
          show={show}
          onHide={() => setShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Details
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body className="show-grid">
              <Form.Group controlId="employeeID">
                <Form.Label>Employee ID/Number</Form.Label>
                <Form.Control
                  name="employee_id"
                  type="employee_id"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.employee_id}
                  placeholder="1010123"
                />
              </Form.Group>
              <Form.Group controlId="nameID">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="name"
                  type="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  placeholder="John Smith"
                />
              </Form.Group>
              <Form.Group controlId="departmenID">
                <Form.Label>Select Department</Form.Label>
                <Dropdown
                  name="department"
                  width="100%"
                  options={departmentOptions}
                  getOptionLabel={option => option.title}
                  getOptionValue={option => option.id}
                  placeholder="Select"
                  onChange={option => {
                    formik.handleChange("department")(option.id)
                    setDepartment(option)
                    setRoles(option.roles)
                    setRole({
                      title: "Select",
                      id: ""
                    })
                  }}
                  value={departmentSelected}
                />
              </Form.Group>
              <Form.Group controlId="role">
                <Form.Label>Role</Form.Label>
                <Dropdown
                  name="role"
                  width="100%"
                  options={roles}
                  getOptionLabel={option => option.title}
                  getOptionValue={option => option.id}
                  placeholder="Select"
                  onChangeHandler={option => {
                    formik.handleChange("role")(option.id)
                    setRole(option)
                  }}
                  value={roleSelected}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="signIn-btn"
                variant="primary"
                type="submit"
                onClick={values => { }}
              >
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </ModalComponent>
      </div>
    </div>
  )
}

export default EmployeeDetails
