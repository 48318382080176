import React from "react"
import { Form } from "react-bootstrap"
import "./inputField.scss"

function InputField(props) {
  return (
    // <Form.Group controlId="confirmPassword" className="confirm-password-field ">
    //   <Form.Label>Confirm Password</Form.Label>
    <Form.Control
      {...props}
      className={`${
        props.formik.touched[props.name] && props.formik.errors[props.name]
          ? "danger-border"
          : ""
      }`}
    />
    //   <Form.Text className="text-danger">
    //     {formikLogin.touched.confirmPassword &&
    //     formikLogin.errors.confirmPassword ? (
    //       <div className="text-danger">
    //         {formikLogin.errors.confirmPassword}
    //       </div>
    //     ) : null}
    //   </Form.Text>
    //   <span
    //     className="eye-icon"
    //     onClick={() => handleEyeClick("confirmPassword")}
    //   >
    //     {type["confirmPassword"].type === "text" ? (
    //       <i className="far fa-eye"></i>
    //     ) : (
    //       <i className="far fa-eye-slash"></i>
    //     )}
    //   </span>
    // </Form.Group>
  )
}

export default InputField
