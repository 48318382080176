import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Modal, Button, Col, Form, Row } from "react-bootstrap"
import EditProfile from "../../../Assets/images/edit_profile.png"
import ProfesssionalDetail from "../../../Assets/images/professional_details.png"
import FileUpload from "../../../Assets/images/file_upload.png"
import ModalComponent from "../../ModalComponent"
import InputField from "../../../Layouts/InputField"
import Dropdown from "../../../Layouts/Dropdown"
import { useHistory, useLocation } from "react-router-dom"
import {
  changePassword,
  getAllEmployers,
  getIndustries,
  changeEmployer
} from "../../../Services/api"
import { notifyError, notifySuccess } from "../../Toast"
import "./myProfile.scss"

function MyProfile() {
  const history = useHistory()
  const location = useLocation()
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const {
    token,
    user: { name, employee }
  } = globalState
  const nameTokens = name ? name.split(" ") : ["", ""]
  const { department, role, employer, job_title, employee_id, joining_letter } =
    employee

  const [show, setShow] = useState({
    showPD: false,
    showCE: false,
    showCP: false
  })
  const { showPD, showCE, showCP } = show
  const [employers, setEmployers] = useState([])
  const [industries, setIndustries] = useState([])
  const [industrySelected, setIndustry] = useState("")
  const [joiningLetter, setJoiningLetter] = useState("")
  const [departments, setDepartments] = useState([])
  const [roles, setRoles] = useState([])
  const [employerSelected, setEmployerSelected] = useState({
    id: "",
    name: "Select"
  })
  const [departmentSelected, setDepartmentSelected] = useState({
    id: "",
    name: "Select"
  })
  const [roleSelected, setRoleSelected] = useState({
    id: "",
    name: "Select"
  })

  const [type, setType] = useState({
    password_1: {
      type: "password"
    },
    password_2: {
      type: "password"
    }
  })

  const [loading, setLoading] = useState(false)

  const formikCE = useFormik({
    initialValues: {
      employer: "",
      department: "",
      role: "",
      id_employee: "",
      job_title: ""
      // joining_letter: ""
    },
    validationSchema: Yup.object({
      employer: Yup.string().required("Select an employer"),
      department: Yup.string().required("Select a department"),
      role: Yup.string().required("Select a role")
    }),
    onSubmit: values => {
      if (formikCE.values.employee_id == "") {
        notifyError("All Fields are required")
      } else {
        employerChangeRequestHandle(values)
      }
    }
  })

  const passwordChangeHandle = async data => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }

      const response = await changePassword(data, headers)
      notifySuccess(response.data.detail)

      setShow(prevState => ({
        ...prevState,
        showCP: false
      }))
    } catch (error) { }
  }

  const formikCP = useFormik({
    initialValues: {
      password_1: "",
      password_2: ""
    },
    validationSchema: Yup.object({
      password_1: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      password_2: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password_1")], "Passwords must match")
    }),
    onSubmit: values => {
      passwordChangeHandle(values)
    }
  })

  const handleEyeClick = name => {
    setType(prevType => {
      return {
        ...prevType,
        [name]:
          prevType[name].type === "password"
            ? {
              type: "text"
            }
            : {
              type: "password"
            }
      }
    })
  }

  const employerChangeRequestHandle = async values => {
    const formData = new FormData()
    formData.append("employer", values.employer)
    formData.append("department", values.department)
    formData.append("role", values.role)
    formData.append("id_employee", values.employee_id)
    formData.append("job_title", values.job_title)
    formData.append("joining_letter", joiningLetter)
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }
      setLoading(true)
      const response = await changeEmployer(formData, headers)
      setShow(prevState => ({
        ...prevState,
        showCE: false
      }))
      setLoading(false)
      setJoiningLetter("")
      notifySuccess(
        "Your request is sent to your last and newly added employer. Please wait until verification is completed."
      )
    } catch (error) {
      setLoading(false)
      if (error.response.status === 500) notifyError("Internal server error")
      if (error.response.status === 400) {
        if (error.response.data.detail != undefined)
          notifyError(error.response.data.detail)
      }
    }
  }

  const fetchEmployers = async query => {
    try {
      const response = await getAllEmployers(query)
      const filterOption = response.data.filter(d => d.name !== null)
      setEmployers(filterOption)
    } catch (error) { }
  }

  const fetchIndustries = async () => {
    try {
      const response = await getIndustries()
      setIndustries(response.data)
    } catch (error) { }
  }

  const getJoiningDate = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]

    const d = new Date(employee.joined_on)
    let joiningDate = "N/A"
    if (d) {
      joiningDate = `${d.getDate()} ${monthNames[d.getMonth()]
        }, ${d.getFullYear()}`
    }
    return joiningDate
  }

  const getOfferLetter = () => {
    const url = employee.joining_letter
    let fileName = "N/A"
    if (url) {
      let arr = url.split("/")
      fileName = arr[arr.length - 1]
    }

    return fileName
  }

  useEffect(() => {
    fetchEmployers()
    fetchIndustries()
    const { state } = location
    if (state?.showPD) {
      setShow({
        showPD: true
      })
    }
  }, [])

  useEffect(() => {
    fetchEmployers(industrySelected)
  }, [industrySelected])

  useEffect(() => {
    if (employers.length) {
      const employerSelected = employers.filter(
        _employer => _employer.id === employer?.id
      )[0]

      const employerDepartments = employerSelected?.employer?.departments
      const departmentSelected = employerDepartments?.filter(
        _department => _department?.id === department.id
      )[0]

      const allRoles = departmentSelected?.roles
      const roleSelected = allRoles?.filter(_role => _role?.id === role?.id)[0]

      formikCE.setFieldValue("employer", employerSelected?.id)
      formikCE.setFieldValue("department", departmentSelected?.id)
      formikCE.setFieldValue("role", roleSelected?.id)
      formikCE.setFieldValue("employee_id", employee_id)
      // job_title does not come from API
      // setFieldValue for job_title :TODO
      formikCE.setFieldValue("job_title", job_title)
      // ? job_title : (roleSelected != undefined ? roleSelected.title : ""))

      setEmployerSelected({
        id: employerSelected?.id,
        name: employerSelected?.name
      })
      setDepartmentSelected({
        id: departmentSelected?.id,
        title: departmentSelected?.title
      })
      setRoleSelected(roleSelected)
    }
  }, [employers])

  const styles = {
    profileCon: { marginBottom: 20, marginLeft: 4, },
  }

  return (
    <div className="container dashboard">
      <Row>
        <div className="my-profile">
          <div style={styles.profileCon}>
            <span className="spantitle">Profile</span>
          </div>
          <div className="flex-container1 profile cards">
            <Row>
              <Col sm={12} md={6} lg={4}>
                <div className="box profile-card">
                  <div className="img-wrapper">
                    <label for="profileImage">
                      <span className="img-cover">
                        {employee && employee.picture ? (
                          <img
                            className="img-cover preview"
                            src={employee.picture}
                            alt={"alt"}
                          />
                        ) : (
                          <i class="far fa-user"></i>
                        )}
                      </span>
                      <div className="camera">
                        <i class="far fa-camera"></i>
                      </div>
                    </label>
                  </div>
                  <div className="emp-details">
                    <span className="name">{name}</span>
                    {/* <span className="ID">
                      ID {employee ? employee.employee_id : ""}
                    </span> */}
                  </div>
                </div>
              </Col>
              <Col sm={12} md={5} lg={3}>
                <div className="box-2">
                  <div
                    className="personal-details"
                    onClick={() => history.push("/profile/edit")}
                  >
                    <img src={EditProfile} />

                    <span className="tag">Personal Details</span>
                  </div>
                  <div
                    className="professional-details"
                    onClick={() =>
                      setShow({
                        showPD: true,
                        showCE: false,
                        showCP: false
                      })
                    }
                  >
                    <img src={ProfesssionalDetail} />
                    <span className="tag">Professsional Details</span>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={5} lg={3}>
                <div className="box-2">
                  <div
                    className="change-employer"
                    onClick={() =>
                      setShow({
                        showPD: false,
                        showCE: true,
                        showCP: false
                      })
                    }
                  >
                    <img src={EditProfile} />
                    <span className="tag">Change employer</span>
                  </div>
                  <div
                    className="change-password"
                    onClick={() =>
                      setShow({
                        showPD: false,
                        showCE: false,
                        showCP: true
                      })
                    }
                  >
                    <img src={EditProfile} />
                    <span className="tag">Change Password</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <ModalComponent
            className="professional-detail-modal"
            modalTitle="Import Employee"
            show={showPD}
            onHide={() =>
              setShow({
                showPD: false,
                showCE: false
              })
            }
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Professional Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <div className="p-card profile">
                <div className="header">
                  <div className="profile-details">
                    <div className="user-img" id="profileImage">
                      {nameTokens.length > 1
                        ? `${nameTokens[0].charAt(0)}${nameTokens[1].charAt(0)}`
                        : `${nameTokens[0].charAt(0)}`}
                    </div>
                    <div className="user-wrapper">
                      <div className="user-details">
                        <span className="designation">
                          {role && role.title}
                        </span>
                        <span className="department">
                          {department && department.title}
                        </span>
                        <span className="address">
                          {employer && employer.name ? employer.name : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body">
                  <div className="details">
                    <div className="detail">
                      <span>Employee ID</span>
                      <span>
                        {employee.employee_id !== "undefined"
                          ? employee.employee_id
                          : "N/A"}
                      </span>
                    </div>
                    <div className="detail">
                      <span>Joined On</span>
                      {getJoiningDate()}
                    </div>
                    <div className="detail">
                      <span>Joining Letter</span>
                      <span className="joining-letter">{getOfferLetter()}</span>
                    </div>
                  </div>
                </div>
                <div className="footer"></div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </ModalComponent>

          <ModalComponent
            className="change-employer-modal"
            modalTitle="Import Employee"
            show={showCE}
            onHide={() =>
              setShow({
                showPD: false,
                showCE: false
              })
            }
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal"
            bsClass="change-employer-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Change Employer
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <Form onSubmit={formikCE.handleSubmit}>
                <Row>
                  <Col sx={12} sm={12} md={6}>
                    <Form.Group
                      controlId="password"
                      className="password-field mb-2"
                    >
                      <Form.Label className="lable-password">
                        New Employer Industry
                      </Form.Label>
                      <Dropdown
                        name="industry"
                        width="400px"
                        options={industries}
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.title}
                        placeholder="Select"
                        onChangeHandler={value => {
                          if (!value) {
                            setIndustry("")
                            setEmployerSelected({ id: "", name: "Select" })
                            setDepartmentSelected({ id: "", title: "Select" })
                            setRoleSelected({ id: "", title: "Select" })
                            return
                          }
                          setIndustry(value.title)
                        }}
                        isClearable
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="password"
                      className="password-field mb-2"
                    >
                      <Form.Label className="lable-password">
                        New Employer
                      </Form.Label>
                      <Dropdown
                        name="employer"
                        width="400px"
                        options={employers}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        placeholder="Select"
                        onChangeHandler={value => {
                          formikCE.handleChange("employer")(value.id)
                          const { departments } = value.employer || []
                          setEmployerSelected(value)
                          setDepartments(departments)
                          setDepartmentSelected({ id: "", title: "Select" })
                          setRoleSelected({ id: "", title: "Select" })
                          setRoles([])
                        }}
                        value={employerSelected}
                      />
                      <Form.Text className="text-danger">
                        {formikCE.touched.employer &&
                          formikCE.errors.employer ? (
                          <div className="text-danger">
                            {formikCE.errors.employer}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="email" className="mb-2">
                      <Form.Label>Department</Form.Label>
                      <Dropdown
                        name="department"
                        width="400px"
                        options={departments}
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.id}
                        placeholder="Select"
                        onChangeHandler={value => {
                          formikCE.handleChange("department")(value.id)
                          setDepartmentSelected(value)
                          setRoles([...value.roles])
                          setRoleSelected({ id: "", title: "Select" })
                        }}
                        value={departmentSelected}
                      />
                      <Form.Text className="text-danger">
                        {formikCE.touched.department &&
                          formikCE.errors.department ? (
                          <div className="text-danger">
                            {formikCE.errors.department}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="email" className="mb-2">
                      <Form.Label>Role</Form.Label>
                      <Dropdown
                        name="role"
                        width="400px"
                        options={roles}
                        getOptionLabel={option => option.title}
                        getOptionValue={option => option.id}
                        placeholder="Select"
                        onChangeHandler={value => {
                          formikCE.handleChange("role")(value.id)
                          setRoleSelected(value)
                        }}
                        value={roleSelected}
                      />
                      <Form.Text className="text-danger">
                        {formikCE.touched.role && formikCE.errors.role ? (
                          <div className="text-danger">
                            {formikCE.errors.role}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col sx={12} sm={12} md={6}>
                    <Form.Group
                      controlId="jobTitle"
                      className="password-field mb-2"
                    >
                      <Form.Label className="lable-password">
                        Job Title (Optional)
                      </Form.Label>
                      <InputField
                        name="jobTitle"
                        onChange={formikCE.handleChange}
                        onBlur={formikCE.handleBlur}
                        value={formikCE.values.job_title}
                        placeholder="eg, Manager"
                        formik={formikCE}
                      />
                      <Form.Text className="text-danger">
                        {formikCE.touched.password &&
                          formikCE.errors.password ? (
                          <div className="text-danger">
                            {formikCE.errors.password}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="empID" className="empID-field mb-2">
                      <Form.Label className="lable-empID">
                        Employee ID
                      </Form.Label>
                      <InputField
                        name="employee_id"
                        onChange={formikCE.handleChange}
                        onBlur={formikCE.handleBlur}
                        value={formikCE.values.employee_id}
                        placeholder="eg, AS12345"
                        formik={formikCE}
                      />
                      <Form.Text className="text-danger">
                        {formikCE.touched.employee_id &&
                          formikCE.errors.employee_id ? (
                          <div className="text-danger">
                            {formikCE.errors.employee_id}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="empID" className="empID-field mb-2">
                      <Form.Label className="lable-empID">
                        Joining Letter
                      </Form.Label>
                      <div className="file-wrapper">
                        <div className="file-name123">
                          {joining_letter
                            ? joining_letter.split("/")[6]
                            : joiningLetter.name}
                        </div>
                        <div className="image-upload">
                          <label for="file-input">
                            <img src={FileUpload} />
                            <span>Upload</span>
                          </label>

                          <input
                            id="file-input"
                            type="file"
                            onChange={e => setJoiningLetter(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={3} lg={3}></Col>
                  <Col sm={12} md={3} lg={3}>
                    <Form.Group
                      controlId="password"
                      className="password-field mb-2"
                    >
                      <Button
                        className="update-btn"
                        variant="primary"
                        type="submit"
                        onClick={values => { }}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>Loading...</span>
                        ) : (
                          <span>Change</span>
                        )}
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={3} lg={3}></Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </ModalComponent>

          <ModalComponent
            className=""
            modalTitle="Import Employee"
            show={showCP}
            onHide={() =>
              setShow({
                showPD: false,
                showCE: false,
                showCP: false
              })
            }
            bsClass="cp-form-wrapper"
            dialogClassName="pc-custom-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Change Password
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <div className="cp-form">
                <Form onSubmit={formikCP.handleSubmit}>
                  <Form.Group
                    controlId="password1"
                    className="password_1-field"
                  >
                    <Form.Label className="lable-password_1">
                      Password
                    </Form.Label>
                    <InputField
                      name="password_1"
                      type={type["password_1"].type}
                      onChange={formikCP.handleChange}
                      onBlur={formikCP.handleBlur}
                      value={formikCP.values.password_1}
                      placeholder="Password"
                      formik={formikCP}
                    />
                    <Form.Text className="text-danger">
                      {formikCP.touched.password_1 &&
                        formikCP.errors.password_1 ? (
                        <div className="text-danger">
                          {formikCP.errors.password_1}
                        </div>
                      ) : null}
                    </Form.Text>
                    <span
                      className="eye-icon"
                      onClick={() => handleEyeClick("password_1")}
                    >
                      {type["password_1"].type === "text" ? (
                        <i className="far fa-eye"></i>
                      ) : (
                        <i className="far fa-eye-slash"></i>
                      )}
                    </span>
                  </Form.Group>
                  <Form.Group
                    controlId="password2"
                    className="password_2-field"
                  >
                    <Form.Label className="lable-password_2">
                      Confirm Password
                    </Form.Label>
                    <InputField
                      name="password_2"
                      type={type["password_2"].type}
                      onChange={formikCP.handleChange}
                      onBlur={formikCP.handleBlur}
                      value={formikCP.values.password_2}
                      placeholder="Password"
                      formik={formikCP}
                    />
                    <Form.Text className="text-danger">
                      {formikCP.touched.password_2 &&
                        formikCP.errors.password_2 ? (
                        <div className="text-danger">
                          {formikCP.errors.password_2}
                        </div>
                      ) : null}
                    </Form.Text>
                    <span
                      className="eye-icon"
                      onClick={() => handleEyeClick("password_2")}
                    >
                      {type["password_2"].type === "text" ? (
                        <i className="far fa-eye"></i>
                      ) : (
                        <i className="far fa-eye-slash"></i>
                      )}
                    </span>
                  </Form.Group>

                  <Form.Group>
                    <Button
                      className="cpupdate-btn"
                      variant="primary"
                      type="submit"
                      onClick={values => { }}
                    >
                      Update
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </ModalComponent>
        </div>
      </Row>
    </div>
  )
}

export default MyProfile
