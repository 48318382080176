import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Alert, Button, Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import SplashContent from "../SplashContent"
import { login, updateUser } from "../../Services/api"
import { useHistory } from "react-router-dom"
import useUser from "../../Context/UserContext"
import InputField from "../../Layouts/InputField"
import "./login.scss"
import { requestForToken } from "../../firebase"

function Login() {
  const history = useHistory()
  const { state, addUserDetails, addGoSteps, addUserType, setLoader } =
    useUser()
  const [formState, setFormState] = useState(true)
  const [type, setType] = useState("password")
  const [error, setError] = useState(undefined)

  const handleEyeClick = e => {
    setType(prevType => {
      return prevType === "password" ? "text" : "password"
    })
  }

  const userLogin = async payload => {
    setLoader({
      loading: true
    })
    login(payload)
      .then(response => {
        // console.log("response", response.data)
        const { token, user } = response.data
        if (user && !user.profile_completed) {
          setLoader({ loading: false })
          history.push("/signup")
          addUserDetails({ token, user })
          addGoSteps({
            goSteps: 1
          })
        } else if (user && user.profile_completed) {
          let clonedUser = JSON.parse(JSON.stringify(user))
          const headers = {
            Authorization: `Token ${token}`
          }
          requestForToken()
            .then(currentToken => {
              if (currentToken !== clonedUser.registration_id) {
                updateUser(
                  user?.id,
                  {
                    registration_id: currentToken
                  },
                  headers
                )
                  .then(res => console.log(res))
                  .catch(error => console.log("updateUser error", error))
              }
            })
            .catch(error => {
              console.log("currentToken error", error)
            })
            .finally(() => {
              setLoader({
                loading: false
              })
              addUserDetails({
                token,
                user: clonedUser
              })
              history.push("/dashboard")
            })
        }
      })
      .catch(error => {
        setLoader({ loading: false })
        if (error.response) {
          setError(error.response.data.detail[0])
        }
      })
  }

  const formik = useFormik({
    initialValues: {
      password: "",
      email: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Password Required"),

      email: Yup.string()
        .email("Invalid email address")
        .required("Email Required")
    }),
    onSubmit: values => {
      userLogin(values)
    }
  })

  useEffect(() => {
    addUserType({ type: "" })
    addUserDetails({})
    // console.log("Reached to Login!");
  }, [])

  return (
    <SplashContent>
      <div className="how-to">How to?</div>
      <div className="enter-cred-text">Enter your Credentials</div>
      <div className="login-form">
        {error && <Alert variant={"danger"}>{error}</Alert>}
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <InputField
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="Your Email"
              formik={formik}
            />
            <Form.Text className="text-danger">
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </Form.Text>
            {/* <p className="your-email">Your Email</p> */}
          </Form.Group>
          <Form.Group controlId="password" className="password-field">
            <Form.Label className="lable-password">Password</Form.Label>
            <InputField
              name="password"
              type={type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              placeholder="Password"
              formik={formik}
            />
            <Form.Text className="text-danger">
              {formik.touched.password && formik.errors.password ? (
                <div className="text-danger">{formik.errors.password}</div>
              ) : null}
            </Form.Text>
            <span className="eye-icon" onClick={handleEyeClick}>
              {type === "text" ? (
                <i className="far fa-eye"></i>
              ) : (
                <i className="far fa-eye-slash"></i>
              )}
            </span>
          </Form.Group>
          <div className="forgot-password">
            <Link to="/forgot-password">Forgot password?</Link>
          </div>
          <Button
            className="signIn-btn"
            variant="primary"
            type="submit"
            onClick={values => setFormState(values)}
          // disabled={formState}
          >
            {state.loading ? <span>Loading...</span> : <span>Sign in</span>}
          </Button>
        </Form>
      </div>
      <div className="circle-or">
        <span>Or</span>
      </div>
      <div className="create-account">
        <Link to="/signup">Create An Account</Link>
      </div>
    </SplashContent>
  )
}

export default Login
