import React, { useState, useEffect } from "react"
import { Button, Image } from "react-bootstrap"
import Notifications from "../../Components/Notifications"
import { Link, useHistory } from "react-router-dom"
import "./header.scss"
import { getNotifications, markNotificationRead } from "../../Services/api"
import { onMessageListener } from "../../firebase"
import UserIcon from "../../Assets/images/user.png"

function Header({ toggleSideNav }) {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [count, setCount] = useState("")

  const type =
    globalState && globalState.user && globalState.user.type === "Employer"
      ? "employer"
      : "employee"
  const { token, user } = globalState

  const fetchNotifications = async () => {
    try {
      setLoading(true)
      const config = {
        headers: { Authorization: `Token ${token}` }
      }
      const response = await getNotifications(config)
      const notReadArr = response.data.filter(
        notification => notification.read === false
      )
      setCount(notReadArr.length)
      setNotifications(response.data)
      setLoading(false)
    } catch (error) { }
  }

  const goToEmployeeDetails = (userId, notificationId, notificationType) => {
    const headers = {
      Authorization: `Token ${token}`
    }
    const payload = {
      notification: notificationId
    }
    markNotificationRead(payload, headers)
      .then(response => {
        fetchNotifications()
        if (user?.type === "Employee") {
          if (notificationType === "Address Change") {
            return history.push("/address")
          }
          return history.push("/profile", {
            showPD: true
          })
        }

        history.push("/invite/employee-details", {
          userId
        })
      })
      .catch(error => console.log("notification error", error))
  }

  onMessageListener()
    .then(payload => {
      console.log("onMessageListener", payload)
      const { data } = payload
      setNotifications(prevState => [
        {
          content: data?.title,
          read: false,
          id: data?.notif_id,
          data: data?.redirect_user_id,
          data_type: data?.type
        },
        ...prevState
      ])
    })
    .catch(err => console.log("failed: ", err))

  useEffect(() => {
    if (token) {
      fetchNotifications()
    }
  }, [token])

  useEffect(() => {
    window.addEventListener("click", function (e) {
      if (document.getElementById("bell-icon-btn").contains(e.target)) {
        // Clicked in bell icon
      } else {
        // Clicked outside the bell icon
        const notificationsContainer =
          document.getElementById("notifications-box")
        if (
          !notificationsContainer.contains(e.target) &&
          notificationsContainer.classList.contains("visible")
        ) {
          // Clicked in bell icon
          onCloseNotificationHandle()
        }
      }
    })
  }, [])

  const onCloseNotificationHandle = () => {
    const notificationsContainer = document.getElementById("notifications-box")
    notificationsContainer.classList.toggle("visible")
  }
  return (
    <header>
      <div className="flex">
        <div className="item-left">
          <div className="hamburger" id="hamburger" onClick={toggleSideNav}>
            <i class="far fa-bars"></i>
          </div>
          <Link to="/dashboard">
            {/* <div className="brand"></div> */}
            <Image
              className="brand"
              src={require("../../Assets/images/Tandem_logo_tagline_RGB_transparent.png")}
              style={{ objectFit: 'contain', }}
            />
          </Link>
        </div>
        <div className="item-right">
          <div className="bell-icon" id="bell-icon-btn">
            {count > 0 && <span>{count}</span>}
            <Button onClick={onCloseNotificationHandle}>
              <i className="fal fa-bell"></i>
            </Button>
          </div>
          <div className="profile-details">
            <Link to="/profile" className="profile-details-link">
              <div className="profile-icon">
                {!user[type].company_logo && !user?.employee?.picture ? (
                  <img src={UserIcon} />
                ) : (
                  <img
                    src={
                      type === "employer"
                        ? user[type].company_logo
                        : user?.employee?.picture
                    }
                    alt={`${type === "employer" ? "Logo" : "Avatar"}`}
                    className="avatar"
                  />
                )}
              </div>
            </Link>
            <div className="details">
              {user[type] && user[type].company_name && (
                <span className="company">
                  {user[type] && user[type].company_name
                    ? user[type].company_name
                    : ""}
                </span>
              )}

              <Link to="/profile" className="profile-details-link">
                <span className="name">{user.name}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Notifications
        loading={loading}
        notifications={notifications}
        setNotifications={setNotifications}
        goToEmployeeDetails={goToEmployeeDetails}
        onCloseNotificationHandle={onCloseNotificationHandle}
      />
    </header>
  )
}

export default Header
