export const initialState = {
  goSteps: 0,
  token: "",
  user: {
    id: "",
    name: null,
    email: "",
    type: "",
    employer: {
      company_logo: null,
      company_name: null,
      contact_number: null,
      official_email: null,
      contact_person: null,
      website: null,
      linkedin: null,
      address: null,
      zip_code: null
    }
  },
  loading: false,
  departmentSelected: {
    id: ""
  }
}

const userReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case "ADD_USER_DETAILS":
      return {
        ...state,
        ...payload
      }
    case "ADD_COMPANY_DETAILS":
      return {
        ...state,
        // products: payload.products
        companyName: payload.companyName,
        contactNumber: payload.contactNumber,
        contactPerson: payload.contactPerson
      }

    case "ADD_USER_TYPE":
      return {
        ...state,
        user: {
          ...state.user,
          type: payload.type
        }
      }

    case "ADD_GO_STEPS":
      return {
        ...state,
        goSteps: payload.goSteps
      }

    case "USER_LOGOUT":
      return {
        ...state,
        user: {},
        token: "",
        goSteps: 0
      }
    case "SET_LOADER":
      return {
        ...state,
        loading: payload.loading
      }
    case "SET_DEPARTMENT":
      return {
        ...state,
        departmentSelected: payload.departmentSelected
      }

    default:
      throw new Error(`No case for type ${type} found in userReducer.`)
  }
}

export default userReducer
