import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { Row, Col, Button, Form } from "react-bootstrap"
import { saveAs } from "file-saver"
import Dropdown from "../../Layouts/Dropdown/Dropdown"
import BarChart from "../BarChart"
import BasicTable from "../Table/BasicTable"
import "./dashboard.scss"
import { useFormik } from "formik"
import {
  getDepartment,
  getBilledAllowanceUsers,
  getBilledAllowances,
  getEmployeesCSV
} from "../../Services/api"
import CustomDatePicker from "../../Layouts/DatePicker"
import { getCurrentMonthDateRange } from "../../utils/localStorageHelper"
import DownloadIcon from "../../Assets/images/download-icon.png"

function Dashboard() {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { user, token } = globalState
  const [departments, setDepartments] = useState([])
  const [department, setDepartment] = useState({
    value: "",
    title: "Select"
  })
  const [roles, setRoles] = useState([])
  const [role, setRole] = useState({
    value: "",
    title: "Select"
  })

  const [chartLoading, setChartLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [totalReimbursmentAmt, setTotalAmount] = useState(0)
  const { startDate: s, endDate: e } = getCurrentMonthDateRange()
  const [startDate, setStartDate] = useState(s)
  const [endDate, setEndDate] = useState(e)

  useEffect(() => {
    const formattedDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1
      }-${startDate.getDate()}`
    formik.setFieldValue("start_date", formattedDate)
  }, [startDate])

  useEffect(() => {
    const formattedDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1
      }-${endDate.getDate()}`
    formik.setFieldValue("end_date", formattedDate)
  }, [endDate])

  const formik = useFormik({
    initialValues: {
      month: "",
      department: "",
      start_date: "",
      end_date: ""
    },
    validationSchema: Yup.object({
      // month: Yup.string().required("Please select month"),
      // department: Yup.string().required("Please select department"),
      // start_date: Yup.string().required("Please select start date"),
      // end_date: Yup.string().required("Please select end date")
      // start_date: Yup.date().default(() => new Date()),
      // end_date: Yup.date().when("start_date", (start_date, schema) => {
      //   return start_date && schema.min(start_date)
      // })
    }),
    onSubmit: values => {
      const { start_date, end_date, department, role } = values
      const query = `start_date=${start_date}&end_date=${end_date}&department=${department}&role=${role}`
      fetchBilledAllowanceUsers(query)
      fetchChartData(`min_date=${start_date}&max_date=${end_date}`)
    }
  })

  const fetchDepartments = async () => {
    try {
      const config = {
        headers: { Authorization: `Token ${token}` },
        params: {
          employer: user.id
        }
      }
      const response = await getDepartment(config)
      setDepartments(response.data)
    } catch (error) { }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id"
      },
      {
        Header: "Name",
        accessor: "employee__user__name"
      },
      {
        Header: "Role",
        accessor: "allowance__role__title"
      },
      {
        Header: "Total Allowance",
        accessor: "total_sales"
      }
    ],
    []
  )
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  })

  const downloadChart = () => { // save to png
    const canvasSave = document.getElementById("stackD")
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "testing.png")
    })
  }


  const downloadEmployees = () => { // donwloading Employees list in csv file 
    let date = new Date().toLocaleDateString();
    getEmployeesCSV(token)
      .then(response => {
        if (response.data) { // code to convert csv data into csv file
          const blob = new Blob([response.data], { type: 'text/csv' })
          const url = window.URL.createObjectURL(blob)
          saveAs(url, `${(user.name).toUpperCase()}-EmplyeeList-${date}.csv`);
        }
      })
      .catch(error =>
        console.log(" ==== Error getting csv file ===== ", error)
      )
  }

  const fetchChartData = async query => {
    const config = {
      headers: { Authorization: `Token ${token}` }
    }
    setChartLoading(true)
    const response = await getBilledAllowances(query, config)
    setChartLoading(false)

    setChartData({
      labels: response?.data?.map(d => d.title),
      datasets: [
        {
          label: "Price in USD",
          data: response?.data?.map(d => d.total.total),
          backgroundColor: "#1ABCF5",
          barPercentage: 0.25,
          barThickness: 16,
          maxBarThickness: 16,
          minBarLength: 2
        }
      ]
    })

    const totalReimbursmentAmt = response.data.reduce(
      (total, current) => total + Number(current.total.total),
      0
    )
    setTotalAmount(totalReimbursmentAmt)
  }

  const fetchBilledAllowanceUsers = async query => {
    try {
      const config = {
        headers: { Authorization: `Token ${token}` }
      }
      setLoading(true)
      const response = await getBilledAllowanceUsers(query, config)
      setLoading(false)

      const users = response.data.map((user, index) => ({
        ...user,
        id: index + 1
      }))
      setUsers(users)
    } catch (error) { }
  }

  useEffect(() => {
    fetchDepartments()
    const { start_date, end_date } = getCurrentMonthDateRange()
    fetchChartData(`min_date=${start_date}&max_date=${end_date}`)
    const query = `start_date=${start_date}&end_date=${end_date}&department=${""}&role=${""}`
    fetchBilledAllowanceUsers(query)
  }, [])

  return (
    <div className="container dashboard">
      <Row>
        <Col xs={12} sm={12} md={12} lg={6} xl={7}>
          <div className="reimbursment-dropdowns">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="first-row">
                <Col sm={12} md={6} className="dept">
                  <Form.Group controlId="department">
                    <Dropdown
                      name="department"
                      width="100%"
                      options={departments}
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option.id}
                      placeholder="Department"
                      onChangeHandler={option => {
                        if (!option) {
                          setDepartment({
                            value: "",
                            title: "Select"
                          })
                          setRole({
                            value: "",
                            title: "Select"
                          })
                          formik.handleChange("department")("")
                          return
                        }

                        setDepartment(option)
                        setRoles(option.roles)
                        formik.handleChange("department")(option.title)
                      }}
                      onBlur={formik.handleBlur}
                      value={department}
                      isClearable
                    />

                    <Form.Text className="text-danger">
                      {formik.touched.department && formik.errors.department ? (
                        <div className="text-danger">
                          {formik.errors.department}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} className="role">
                  <Form.Group controlId="role">
                    <Dropdown
                      name="role"
                      width="100%"
                      options={roles}
                      getOptionLabel={option => option.title}
                      getOptionValue={option => option.id}
                      placeholder="Role"
                      onChangeHandler={option => {
                        if (!option) {
                          setRole({
                            value: "",
                            title: "Select"
                          })
                          formik.handleChange("role")("")
                          return
                        }

                        setRole(option)
                        formik.handleChange("role")(option.title)
                      }}
                      onBlur={formik.handleBlur}
                      value={role}
                      isClearable
                    />

                    <Form.Text className="text-danger">
                      {formik.touched.department && formik.errors.department ? (
                        <div className="text-danger">
                          {formik.errors.department}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={3}>
                  <Form.Group controlId="start_date" className="start_date">
                    <CustomDatePicker
                      startDate={startDate}
                      setStartDate={setStartDate}
                    />
                    <Form.Text className="text-danger">
                      {formik.touched.start_date && formik.errors.start_date ? (
                        <div className="text-danger">
                          {formik.errors.start_date}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col sm={12} md={6} lg={3}>
                  <Form.Group controlId="end_date" className="end_date">
                    <CustomDatePicker
                      startDate={endDate}
                      setStartDate={setEndDate}
                    />
                    <Form.Text className="text-danger">
                      {formik.touched.end_date && formik.errors.end_date ? (
                        <div className="text-danger">
                          {formik.errors.end_date}
                        </div>
                      ) : null}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col sm={12} md={12} lg={6}>
                  <div className="go-btn">
                    <Button
                      className="signIn-btn"
                      type="submit"
                      onClick={values => { }}
                    >
                      Go
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="reimbursment">
            <span className="text">Total Reimbursement</span>
            <span className="sum">{`$${totalReimbursmentAmt}`}</span>
          </div>
          <div className="reimbursment-chart">
            <div className="firstRow">
              <span>Allowance vs Amount</span>
              <div onClick={downloadChart} style={styles.container2}>
                <img style={{ width: "18px", marginRight: "0px", }} src={DownloadIcon} />
                <p style={{ marginBottom: "-1px", marginLeft: "4px", }} class="download-btn">Download</p>
              </div>
            </div>
            <div className="line"></div>
            <div className="chart-container">
              {loading ? (
                <h3>Loading...</h3>
              ) : (
                <BarChart
                  id="stackD"
                  chartData={chartData}
                  loading={chartLoading}
                />
              )}
            </div>
          </div>
        </Col>
        <div className="devider"></div>
        <Col xs={12} sm={12} md={12} lg={6} xl={5}>
          <div className="reimbursment-table users-table">
            <div style={styles.container}>
              <p>Users</p>
              <div onClick={downloadEmployees} style={styles.container2}>
                <img style={styles.icon} src={DownloadIcon} />
                <p class="download-btn">Download CSV</p>
              </div>
            </div>
            <BasicTable columns={columns} data={users} loading={loading} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

const styles = {
  container: { display: 'flex', alignItems: 'center', justifyContent: "space-between", },
  container2: { display: 'flex', alignItems: 'center', marginRight: '35px', cursor: "pointer", },
  icon: { width: "18px", marginRight: "-11px", },
}

export default Dashboard
