export const loadFromLocalStorage = key => {
  try {
    return JSON.parse(window.localStorage.getItem(key), true)
  } catch {
    return undefined
  }
}

export const saveToLocalStorage = (key, state) => {
  if (state === undefined) {
    window.localStorage.removeItem(key)
  } else {
    const serializedState = JSON.stringify(state)
    window.localStorage.setItem(key, serializedState)
  }
}

export function dataFormatter(date, format = "YYYY-MM-DD") {
  return new Date(date).toISOString().slice(0, 10)
}

export function getCurrentMonthDateRange() {
  const date = new Date()
  const firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1)
  const today = date

  return {
    startDate: firstDayCurrentMonth,
    endDate: today,
    start_date: dataFormatter(firstDayCurrentMonth),
    end_date: dataFormatter(today)
  }
}
