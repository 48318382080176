import React, { useState } from "react"
import { useTable, usePagination } from "react-table"
import GreenCheck from "../../Assets/images/green_check.png"
import GreyCheck from "../../Assets/images/grey_check.png"
import Thrash from "../../Assets/images/thrash.png"
import { Button, Form } from "react-bootstrap"
import Dropdown from "../../Layouts/Dropdown/Dropdown"
import { allowanceFrquency } from "./AssignAllowance"
// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row,
  row: { index },
  column: { id },
  updateAllowanceData, // This is a custom function that we supplied to our table instance
  departments,
  type,
  onDeptChange,
  rolesAtIndex,
  onClickGreyCheck,
  deleteAllowance,
  loading
}) => {
  // console.log("EditableCell", id)
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = e => {
    setValue(e.target.value)
  }
  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateAllowanceData(index, id, value, true)
  }
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (id === "department") {
    return (
      <Dropdown
        options={departments}
        getOptionLabel={option => option.title}
        getOptionValue={option => option.id}
        onChange={dept => {
          setValue(dept)
          onDeptChange(dept, index)
        }}
        value={value}
        onBlur={onBlur}
        width={"150px"}
      />
    )
  }

  if (id === "role") {
    return (
      <Dropdown
        value={value}
        options={rolesAtIndex[index] ? rolesAtIndex[index].roles : []}
        getOptionLabel={option => option.title}
        getOptionValue={option => option.id}
        onBlur={onBlur}
        onChange={role => setValue(role)}
        width={"150px"}
      />
    )
  }

  if (id === "type") {
    return (
      <Dropdown
        value={value}
        options={type}
        getOptionLabel={option => option.title}
        getOptionValue={option => option.id}
        onBlur={onBlur}
        onChange={type => setValue(type)}
        width={"200px"}
      />
    )
  }

  if (id === "frequency") {
    return (
      <Dropdown
        value={{
          value,
          label: value
        }}
        options={allowanceFrquency}
        // getOptionLabel={option => option.title}
        // getOptionValue={option => option.id}
        onBlur={onBlur}
        onChange={frequencyObj => setValue(frequencyObj.value)}
        width={"200px"}
      />
    )
  }

  if (id === "action") {
    return (
      <div className="action-btn">
        {(row.original.isNew || row.original.isEditing) && loading ? (
          <h6>Loading...</h6>
        ) : (
          <>
            <Button onClick={() => onClickGreyCheck(index)}>
              <img
                src={
                  row.original.isNew || row.original.isEditing
                    ? GreyCheck
                    : GreenCheck
                }
              />
            </Button>
            <Button onClick={() => deleteAllowance(index)}>
              <img src={Thrash} />
            </Button>
          </>
        )}
      </div>
    )
  }

  return (
    <Form.Group controlId="price">
      <Form.Control
        name="price"
        type="price"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={id}
      />
    </Form.Group>
  )
  // console.log("initialValue", initialValue);
  return initialValue
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell
}

// Be sure to pass our updateAllowanceData and the skipPageReset option
function Table({
  columns,
  data,
  updateAllowanceData,
  skipPageReset,
  departments,
  type,
  onClickGreyCheck,
  deleteAllowance,
  loading
}) {
  const [roles, setRoles] = useState([])
  const [rolesAtIndex, setRolesAtIndex] = useState({
    0: roles
  })
  //   const [departments, setDepartments] = useState([])

  const onDeptChange = (dept, index) => {
    setRoles(dept.roles)
    setRolesAtIndex(prevState => ({
      ...prevState,
      [index]: {
        roles: JSON.parse(JSON.stringify(dept.roles))
      }
    }))
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateAllowanceData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateAllowanceData,
      departments,
      type,
      setRoles,
      roles,
      onDeptChange,
      rolesAtIndex,
      onClickGreyCheck,
      deleteAllowance,
      loading
    },
    usePagination
  )

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                className={`${row.original.isNew ? "new-row" : ""}`}
              >
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

function AllowancesTable({
  departments,
  type,
  data,
  updateAllowanceData,
  onClickGreyCheck,
  deleteAllowance,
  loading
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Department",
        accessor: "department"
      },
      {
        Header: "Role",
        accessor: "role"
      },

      {
        Header: "Type",
        accessor: "type"
      },
      {
        Header: "Price",
        accessor: "price"
      },
      {
        Header: "Frequency",
        accessor: "frequency"
      },
      {
        Header: "Action",
        accessor: "action"
      }
    ],
    []
  )

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        updateAllowanceData={updateAllowanceData}
        departments={departments}
        type={type}
        onClickGreyCheck={onClickGreyCheck}
        deleteAllowance={deleteAllowance}
        loading={loading}
      />
    </div>
  )
}

export default AllowancesTable
