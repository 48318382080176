// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getMessaging, getToken, onMessage } from "firebase/messaging"

const REPLACE_WITH_YOUR_VAPID_KEY =
  "BI-SsRGGL1d45LeqYujAhL9h9XnsujLhsWt48d2YIxPFKsrdL_VpJVd24IvoBkTXq8ouz1MgqGdRZBzvRJs0VFI"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVyn7zXfA7PFW_KVBOIaNH59EwzDBOGJk",
  authDomain: "tandem-d21e8.firebaseapp.com",
  projectId: "tandem-d21e8",
  storageBucket: "tandem-d21e8.appspot.com",
  messagingSenderId: "723164570615",
  appId: "1:723164570615:web:72d47bf876d1f4f8fbc4f2"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const messaging = getMessaging()

export const requestForToken = () =>
  new Promise((resolve, reject) => {
    return getToken(messaging, { vapidKey: REPLACE_WITH_YOUR_VAPID_KEY })
      .then(currentToken => {
        if (currentToken) {
          // console.log("current token for client: ", currentToken)
          resolve(currentToken)
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          )
        }
      })
      .catch(err => {
        reject(err)
        console.log("An error occurred while retrieving token. ", err)
      })
  })

export const onMessageListener = () =>
  new Promise(resolve => {
    return onMessage(messaging, payload => {
      console.log("payload", payload)
      resolve(payload)
    })
  })
