import React from "react"
import AssignedAllowance from "../../Assets/images/assigned_allowances.png"

function AssignedAllowancesCard({ allowances, total_allowance }) {
  return (
    <div className="p-card assigned-allowance">
      <div className="allowance-header">
        <div style={{ display: 'flex', }} className="title">
          <span className="icon">
            <img src={AssignedAllowance} />
          </span>
          <span className="text">Assigned Allowances</span>
        </div>
      </div>
      <div className="line"></div>
      <div className="body">
        <div className="allowances">
          {allowances.map(allowance => (
            <div className="allowance">
              <span>{allowance.type.title}</span>
              <span>{`$ ${allowance.price}`}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="line"></div>

      <div className="footer">
        <div className="allowance">
          <span> Total</span>
          <span>{`$ ${total_allowance?.price__sum || 0}`}</span>
        </div>
      </div>
    </div>
  )
}

export default AssignedAllowancesCard
