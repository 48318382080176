import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Container, Row, Form, Alert } from "react-bootstrap"
import { Stepper, Step } from "react-form-stepper"
import useUser from "../../Context/UserContext"
import { useHistory, Link } from "react-router-dom"
import InputField from "../../Layouts/InputField/InputField"
import "./companyDetails.scss"

const initialState = { alt: "", src: "" }

const CompanyDetails = ({
  createUser,
  updateCompanyDetails,
  error,
  addUserType,
  companyName
}) => {
  const history = useHistory()
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { token } = globalState
  const [{ alt, src }, setPreview] = useState(initialState)

  const { state, addGoSteps, addUserDetails } = useUser()
  const { goSteps } = state
  const [formState, setFormState] = useState(true)
  const [company_logo, setCompanyLogoHandle] = useState("")
  const [type, setType] = useState({
    password: {
      type: "password"
    },
    confirmPassword: {
      type: "password"
    }
  })

  const fileHandler = event => {
    const { files } = event.target
    setPreview(
      files.length
        ? {
            src: URL.createObjectURL(files[0]),
            alt: files[0].name
          }
        : initialState
    )
    setCompanyLogoHandle(files[0])
  }

  const handleEyeClick = name => {
    console.log(name)
    setType(prevType => {
      return {
        ...prevType,
        [name]:
          prevType[name].type === "password"
            ? {
                type: "text"
              }
            : {
                type: "password"
              }
      }
    })
  }
  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    contact_number: "",
    contact_person: ""
  })

  const formik = useFormik({
    initialValues: {
      companyName: "",
      contactNumber: "",
      contactPerson: ""
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Please enter company name"),
      //   .max(15, "Must be 15 characters or less")
      //   .required("Required"),
      contactNumber: Yup.string()
        .matches(/^[+\d][1-9][0-9]{5,14}/, {
          message: "Please enter a valid phone number",
          excludeEmptyString: false
        })
        .required("Please enter a valid phone number"),
      contactPerson: Yup.string().required("Please enter contact person.")
    }),
    onSubmit: values => {
      console.log("addCompanyDetails", values)
      setCompanyDetails(prevState => ({
        ...prevState,
        company_name: values.companyName,
        contact_number: values.contactNumber,
        contact_person: values.contactPerson
      }))
      // addCompanyDetails(values)
      // setCompanyDetails(values)
      // alert(JSON.stringify(values, null, 2))
      addGoSteps({
        goSteps: 2
      })
      // setFormState(values);
    }
  })

  const formikLogin = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match")
    }),
    onSubmit: values => {
      try {
        console.log(values)
        createUser(values)
      } catch (error) {}
    }
  })

  const formikLocation = useFormik({
    initialValues: {
      website: "",
      linkedin: "",
      address: "",
      zipCode: "",
      official_email: ""
    },
    validationSchema: Yup.object({
      // website: Yup.string("Website can not be left blank.")
      //   .required("Website can not be left blank.")
      //   .matches(
      //     /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //     "Enter a valid URL."
      //   )
      // linkedin: Yup.string()
      //   .max(15, "Must be 15 characters or less")
      //   .required("Required"),
      // address: Yup.string()
      //   .max(15, "Must be 15 characters or less")
      //   .required("Required"),
      // zipCode: Yup.string()
      //   .max(15, "Must be 15 characters or less")
      //   .required("Required")
    }),
    onSubmit: values => {
      const employer = {
        ...companyDetails,
        ...values
      }
      const formData = new FormData()
      formData.append("name", employer.company_name)
      formData.append("employer.address", employer.address)
      formData.append("employer.company_name", employer.company_name)
      formData.append("employer.contact_number", employer.contact_number)
      formData.append("employer.contact_person", employer.contact_person)
      formData.append("employer.linkedin", employer.linkedin)
      formData.append("employer.official_email", employer.official_email)
      formData.append("employer.website", employer.website)
      formData.append("employer.zip_code", employer.zipCode)
      formData.append("profile_completed", "True")
      formData.append("employer.company_logo", company_logo)
      updateCompanyDetails(formData, errors => {
        const { website, linkedin } = errors.employer
        console.log(website, linkedin)
        formikLocation.setFieldError("website", "Enter a valid URL.")
        formikLocation.setFieldError("linkedin", "Enter a valid URL.")
      })
    }
  })

  const backToLogin = () => {
    localStorage.clear()

    // addUserType({
    //   type: ""
    // })
    addUserDetails({})
    history.push("/login")
  }

  return (
    <div className={`stepper-${goSteps}`}>
      <Stepper activeStep={goSteps}>
        <Step
          onClick={() => {
            if (!token) {
              addGoSteps({
                goSteps: 0
              })
            }
          }}
        />
        <Step
          onClick={() =>
            addGoSteps({
              goSteps: 1
            })
          }
        />
        <Step
          onClick={() =>
            addGoSteps({
              goSteps: 2
            })
          }
        />
      </Stepper>
      {goSteps === 1 && (
        <div>
          <div>
            <div className="company-details">Company Details</div>
            <div className="fileUpload-wrapper">
              <span className="img-cover">
                {src ? (
                  <img className="img-cover preview" src={src} alt={alt} />
                ) : (
                  <div className="logo"></div>
                )}
              </span>
              <div>
                <label className="btn fileUpload btn-default">
                  <span>
                    Browse{" "}
                    <input type="file" hidden="" onChange={fileHandler} />
                  </span>
                </label>
                <p> Jpg, Jpeg or Png less than 1MB</p>
              </div>
            </div>
            <div className="login-form">
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="companyName">
                  <Form.Label>Company Name</Form.Label>
                  <InputField
                    name="companyName"
                    type="companyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                    placeholder="eg, Company LLC"
                    formik={formik}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <div className="text-danger">
                        {formik.errors.companyName}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="contactNumber">
                  <Form.Label>Contact Number</Form.Label>
                  <InputField
                    name="contactNumber"
                    type="contactNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactNumber}
                    placeholder="+1 xxx xxx xxxx"
                    formik={formik}
                  />

                  <Form.Text className="text-danger">
                    {formik.touched.contactNumber &&
                    formik.errors.contactNumber ? (
                      <div className="text-danger">
                        {formik.errors.contactNumber}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="contactPerson">
                  <Form.Label>Contact Person</Form.Label>
                  <InputField
                    name="contactPerson"
                    type="contactPerson"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contactPerson}
                    placeholder="Your Name"
                    formik={formik}
                  />

                  <Form.Text className="text-danger">
                    {formik.touched.contactPerson &&
                    formik.errors.contactPerson ? (
                      <div className="text-danger">
                        {formik.errors.contactPerson}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
                <Button
                  className="signIn-btn"
                  variant="primary"
                  type="submit"
                  onClick={values => setFormState(values)}
                >
                  Continue
                </Button>
              </Form>
              <div className="create-account">
                <Link to="" onClick={backToLogin}>
                  Back To Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      {goSteps === 0 && (
        <div>
          <div className="company-details"> Login Details</div>
          <div className="login-form">
            {error && <Alert variant={"danger"}>{error}</Alert>}

            <Form onSubmit={formikLogin.handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label>Company Email</Form.Label>
                <InputField
                  name="email"
                  type="email"
                  onChange={formikLogin.handleChange}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.email}
                  placeholder="mail@company.com"
                  formik={formikLogin}
                />
                <Form.Text className="text-danger">
                  {formikLogin.touched.email && formikLogin.errors.email ? (
                    <div className="text-danger">
                      {formikLogin.errors.email}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="password" className="password-field ">
                <Form.Label>Password</Form.Label>
                <InputField
                  name="password"
                  type={type["password"].type}
                  onChange={formikLogin.handleChange}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.password}
                  placeholder="Your Password"
                  formik={formikLogin}
                />
                <Form.Text className="text-danger">
                  {formikLogin.touched.password &&
                  formikLogin.errors.password ? (
                    <div className="text-danger">
                      {formikLogin.errors.password}
                    </div>
                  ) : null}
                </Form.Text>
                <span
                  className="eye-icon"
                  onClick={() => handleEyeClick("password")}
                >
                  {type["password"].type === "text" ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </span>
              </Form.Group>
              <Form.Group
                controlId="confirmPassword"
                className="confirm-password-field "
              >
                <Form.Label>Confirm Password</Form.Label>
                <InputField
                  name="confirmPassword"
                  type={type["confirmPassword"].type}
                  onChange={formikLogin.handleChange}
                  onBlur={formikLogin.handleBlur}
                  value={formikLogin.values.confirmPassword}
                  placeholder="Confirm Password"
                  formik={formikLogin}
                />
                <Form.Text className="text-danger">
                  {formikLogin.touched.confirmPassword &&
                  formikLogin.errors.confirmPassword ? (
                    <div className="text-danger">
                      {formikLogin.errors.confirmPassword}
                    </div>
                  ) : null}
                </Form.Text>
                <span
                  className="eye-icon"
                  onClick={() => handleEyeClick("confirmPassword")}
                >
                  {type["confirmPassword"].type === "text" ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </span>
              </Form.Group>
              <Button
                className="signIn-btn"
                variant="primary"
                type="submit"
                onClick={values => setFormState(values)}
              >
                Continue
              </Button>
            </Form>
            <div className="create-account">
              <Link to="" onClick={backToLogin}>
                Back To Login
              </Link>
            </div>
          </div>
        </div>
      )}
      {goSteps === 2 && (
        <div>
          <div className="company-details"> Location Details</div>
          <div className="login-form">
            <Form onSubmit={formikLocation.handleSubmit}>
              <Form.Group controlId="website">
                <Form.Label>Website</Form.Label>
                <InputField
                  name="website"
                  type="website"
                  onChange={formikLocation.handleChange}
                  onBlur={formikLocation.handleBlur}
                  value={formikLocation.values.website}
                  placeholder="Website"
                  formik={formikLocation}
                />

                <Form.Text className="text-danger">
                  {formikLocation.touched.website &&
                  formikLocation.errors.website ? (
                    <div className="text-danger">
                      {formikLocation.errors.website}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="linkedin">
                <Form.Label>LinkedIn</Form.Label>
                <InputField
                  name="linkedin"
                  type="linkedin"
                  onChange={formikLocation.handleChange}
                  onBlur={formikLocation.handleBlur}
                  value={formikLocation.values.linkedin}
                  placeholder="www.linkedin.com/in/xxxx"
                  formik={formikLocation}
                />
                <Form.Text className="text-danger">
                  {formikLocation.touched.linkedin &&
                  formikLocation.errors.linkedin ? (
                    <div className="text-danger">
                      {formikLocation.errors.linkedin}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <InputField
                  name="address"
                  type="address"
                  onChange={formikLocation.handleChange}
                  onBlur={formikLocation.handleBlur}
                  value={formikLocation.values.address}
                  placeholder="eg, street name"
                  formik={formikLocation}
                />
                <Form.Text className="text-danger">
                  {formikLocation.touched.address &&
                  formikLocation.errors.address ? (
                    <div className="text-danger">
                      {formikLocation.errors.address}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="zipCode">
                <Form.Label>ZIP Code</Form.Label>
                <InputField
                  name="zipCode"
                  type="zipCode"
                  onChange={formikLocation.handleChange}
                  onBlur={formikLocation.handleBlur}
                  value={formikLocation.values.zipCode}
                  placeholder="eg, CA XXXXX"
                  formik={formikLocation}
                />
                <Form.Text className="text-danger">
                  {formikLocation.touched.zipCode &&
                  formikLocation.errors.zipCode ? (
                    <div className="text-danger">
                      {formikLocation.errors.zipCode}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Button
                className="signIn-btn"
                variant="primary"
                type="submit"
                onClick={values => setFormState(values)}
              >
                Update
              </Button>
            </Form>

            <div className="create-account">
              <Link to="" onClick={backToLogin}>
                Back To Login
              </Link>
            </div>
          </div>
        </div>
      )}
      {goSteps === 3 && (
        <div className="stepper-success">
          <div className="success-page login-form">
            <div className="logo">
              <i className="fas fa-check-circle"></i>
            </div>
            <div className="title">Success</div>
            <div className="message">
              {companyName}, has been registered Successfully. Please proceed to
              login.{" "}
            </div>
            <Button
              className="signIn-btn"
              variant="primary"
              onClick={() => {
                localStorage.clear()
                addGoSteps({
                  goSteps: 0
                })
                history.push("/login")
              }}
            >
              Login
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CompanyDetails
