import React from "react"
import { Button } from "react-bootstrap"
import EditIcon from "../../Assets/images/edit.png"

function EmployeeProfileCard({
  picture,
  name,
  gender,
  role,
  department,
  isApproved,
  showApproveBtn,
  formik,
  employee_id,
  setShow,
  email,
  phone,
  dob
}) {
  return (
    <div className="p-card profile">
      <div className="header">
        <div className="profile-details">
          <div className="user-img">
            <img src={picture} />
          </div>
          <div className="user-wrapper">
            <div className="user-details">
              <div className="name">
                <span className="emp-name">{name} </span>
                <span className="vertical-line">|</span>
                <span className="gender">{gender} </span>
              </div>
              <div className="designation">
                {role && role.id ? role.title : role}
              </div>
              <div className="department">
                {department && department.id ? department.title : department}
              </div>
            </div>
          </div>

          <span className="icon">
            {!(!isApproved && showApproveBtn) && (
              <Button
                className="edit-profile"
                onClick={() => {
                  formik.setFieldValue("name", name)
                  formik.setFieldValue("employee_id", employee_id)

                  setShow(true)
                }}
              >
                <img src={EditIcon} />
              </Button>
            )}
          </span>
        </div>
      </div>
      <div className="line"></div>
      <div className="body">
        <div className="details">
          <div className="detail">
            <span>Employee ID</span>
            <span>{employee_id}</span>
          </div>
          <div className="detail">
            <span>Email</span>
            <span>{email}</span>
          </div>
          <div className="detail">
            <span>Number</span>
            <span>{phone}</span>
          </div>
          <div className="detail">
            <span>Date of Birth</span>
            <span>{dob}</span>
          </div>
        </div>
      </div>
      <div className="footer"></div>
    </div>
  )
}

export default EmployeeProfileCard
