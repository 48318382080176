import React from "react"
import { Col, Row, Image } from "react-bootstrap"
import "./splashContent.scss"

function SplashContent({ children }) {
  return (
    <div
      className="container landing-page-wrapper"
      style={{ overflow: "hidden" }}
    >
      <Row>
        <Col xs={12} sm={12} md={8} className="">
          <div className="column-left">
            <div className="tandem-logo-wrapper">
              {/* <div className="logo"></div> */}
              <Image
                src={require("../../Assets/images/Tandem_logo_tagline_RGB_transparent.png")}
                width={270}
                height={100}
                style={{ objectFit: 'contain', }}
              />
            </div>
            <div className="tandem-frame-wrapper">
              <div className="frame"></div>
            </div>
            <div className="work-from-home">
              <p>Work From Home Usage</p>
            </div>
            <div className="work-from-home-details">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Ultricies rhoncus orci fringilla aenean sit. Arcu ut nullam
                mattis sed egestas. Vel scelerisque ante placerat justo auctor
                orci, cras faucibus suspendisse. Commodo sapien, in lorem
                viverra adipiscing.
              </p>
            </div>
            <div className="ellipses"></div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={4} className="column-right-conainer">
          <div className="column-right">{children}</div>
        </Col>
      </Row>
    </div>
  )
}

export default SplashContent
