import React, { useState, useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import Header from "../Header"
import Sidebar from "../Sidebar"
import Dashboard from "../../Components/Dashboard"
import Analytics from "../../Components/Analytics"
import Allowances from "../../Components/Allowances"
import MyProfile from "../../Components/Employee/MyProfile"
import EditMyProfile from "../../Components/Employee/EditMyProfile"
import { ModalProvider } from "../../Context/ModalContext"
import AssignedAllowances from "../../Components/Employee/AssignedAllowances"
import useUser from "../../Context/UserContext"
import Toast from "../../Components/Toast"
import Address from "../../Components/Employee/Address"
import EditAddress from "../../Components/Employee/EditAddress"
import TermsAndConditions from "../../Components/Employee/TermsAndConditions"
import EmployerProfile from "../../Components/EmployerProfile"
import EmployeeDetails from "../../Components/EmployeeDetails"
import { getUser } from "../../Services/api"
import "./main.scss"

function Main() {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { token, user } = globalState
  const { addUserDetails } = useUser()
  useEffect(() => {
    if (user.type === "Employee") {
      const config = {
        headers: { Authorization: `Token ${token}` }
      }
      getUser(user.id, config)
        .then(response => {
          addUserDetails({
            user: response.data
          })
        })
        .catch(error => console.log(error))
    }
  }, [])

  const toggleSideNav = () => {
    const sideNav = document.getElementsByTagName("nav")
    sideNav[0].classList.toggle("marginLeft")
  }

  useEffect(() => {
    window.addEventListener("click", function (e) {
      if (document.getElementById("nav-wrapper").contains(e.target)) {
        const sideNav = document.getElementsByTagName("nav")
        if (sideNav[0].classList.contains("marginLeft")) {
          sideNav[0].classList.toggle("marginLeft")
        }
      }
    })
  }, [])

  return (
    <div className="main-container">
      <Header toggleSideNav={toggleSideNav} />
      <div className="main-content">
        <Sidebar toggleSideNav={toggleSideNav} />
        <ModalProvider>
          {user.type === "Employer" ? (
            <Switch>
              <Route path="/dashboard" component={() => <Dashboard />} />
              <Route exact path="/invite" component={Analytics} />
              <Route path="/allowances" component={Allowances} />
              <Route exact path="/profile" component={EmployerProfile} />
              <Route
                exact
                path="/invite/employee-details"
                component={EmployeeDetails}
              />
            </Switch>
          ) : (
            <Switch>
              {/* <Route path="/allowances" component={AssignedAllowances} /> */}
              <Route path="/dashboard" component={AssignedAllowances} />
              <Route exact path="/profile" component={MyProfile} />
              <Route exact path="/profile/edit" component={EditMyProfile} />
              <Route path="/address" component={Address} />
              <Route path="/address-edit" component={EditAddress} />
              <Route path="/terms-conditions" component={TermsAndConditions} />
            </Switch>
          )}
          <Toast />
        </ModalProvider>
      </div>
    </div>
  )
}

export default Main
