import React, { useState, useRef } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Container, Row, Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import SplashContent from "../SplashContent"
import useUser from "../../Context/UserContext"
import { useHistory } from "react-router-dom"
import usePersistedState from "../../hooks/usePersistedState"

function UserType({ setUserType, createUser }) {
  const history = useHistory()
  const { state, addGoSteps, addUserDetails } = useUser()

  const [formState, setFormState] = useState(true)
  const [type, setType] = useState("")
  const employeeRef = useRef()
  const employerRef = useRef()

  const userTypeHandler = e => {
    const { value, checked } = e.target
    setType(value)
    employeeRef.current.classList.remove("employee-checkbox")
    employerRef.current.classList.remove("employer-checkbox")
    if (value === "Employee") {
      employeeRef.current.classList.add("employee-checkbox")
    } else if (value === "Employer") {
      employerRef.current.classList.add("employer-checkbox")
    }
  }

  const formik = useFormik({
    initialValues: {
      employee: "",
      employer: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),

      email: Yup.string().email("Invalid email address").required("Required")
    }),
    onSubmit: values => {
      createUser(values)
    }
  })

  const backToLogin = () => {
    localStorage.clear()
    setUserType({
      type: ""
    })
    addUserDetails({})
    history.push("/login")
  }
  return (
    <React.Fragment>
      <div className="how-to">How to?</div>
      <div className="enter-cred-text">Create an Account For</div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="signup-form">
          <label className="checkbox" ref={employeeRef}>
            <div className="employee-logo"></div>
            <div className="employee-text">Employee</div>

            <input
              type="radio"
              onChange={userTypeHandler}
              name="CheckBoxInputName"
              value="Employee"
              id="CheckBox1"
            />
            <span></span>
          </label>
          <label className="checkbox" ref={employerRef}>
            <div className="employer-logo"></div>
            <div className="employee-text">Employer</div>

            <input
              type="radio"
              onChange={userTypeHandler}
              name="CheckBoxInputName"
              value="Employer"
              id="CheckBox1"
            />
            <span></span>
          </label>

          <Button
            className="signIn-btn"
            variant="primary"
            // type="submit"
            onClick={() => setUserType({ type })}
          >
            Continue
          </Button>
        </div>
      </Form>

      <div className="circle-or">
        <span>Or</span>
      </div>
      <div className="create-account">
        <Link to="" onClick={backToLogin}>
          Log In To Your Account
        </Link>
      </div>
    </React.Fragment>
  )
}

export default UserType
