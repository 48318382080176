import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Form, Modal } from "react-bootstrap"
import AddIcon from "../../Assets/images/add_icon.png"
import ModalComponent from "../ModalComponent"
import BasicTable from "../Table/BasicTable"

function Departments({ onClickAddHandler, data, onClickRowHandler }) {
  const [show, setShow] = useState(false)
  const formik = useFormik({
    initialValues: {
      title: ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Department name is Required")
    }),
    onSubmit: values => {
      onClickAddHandler(values, () => {
        formik.setFieldValue("title", "")
        setShow(false)
      })
    }
  })
  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "psuedoId"
      },
      {
        Header: "Department",
        accessor: "title"
      }
    ],
    []
  )

  const prepareData = React.useMemo(
    () => data.map((d, index) => ({ ...d, psuedoId: index + 1 })),
    [JSON.stringify(data)]
  )

  return (
    <div className="departments-card column">
      <div className="header">
        <span>Departments</span>
        <Button className="add-icon" onClick={() => setShow(true)}>
          <img src={AddIcon} />
        </Button>
      </div>
      <div className="body">
        <div className="users-table">
          <BasicTable
            columns={columns}
            data={prepareData}
            onClickRowHandler={onClickRowHandler}
          />
        </div>
      </div>
      <ModalComponent
        className="manage-allowance"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Department
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body className="show-grid">
            <div className="add-wrapper">
              <Form.Group controlId="name">
                <Form.Label>Department Name</Form.Label>
                <Form.Control
                  name="title"
                  type="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  placeholder="Eg, Human Resource"
                />
                <Form.Text className="text-danger">
                  {formik.touched.name && formik.errors.title ? (
                    <div className="text-danger">{formik.errors.title}</div>
                  ) : null}
                </Form.Text>
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="signIn-btn"
              variant="primary"
              type="submit"
              onClick={values => {}}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </ModalComponent>
    </div>
  )
}

export default Departments
