import React from "react"
import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import EditAddress from "../../../Assets/images/address_edit.png"
import { notifyWarn } from "../../Toast"
import "./address.scss"

function Address() {
  const history = useHistory()
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { user: { employee } } = globalState
  const {
    country,
    new_country,
    city,
    new_city,
    home_size,
    new_home_size,
    zip_code,
    new_zip_code,
    street,
    new_street,
    home_allowance,
    new_home_allowance,
    original_home_allowance,
    new_original_home_allowance,
    home_office_usage,
    new_home_office_usage,
    address_change_requested,
    employer
  } = employee

  const onClickEdit = () => {
    if (!employer?.id) {
      return notifyWarn("You do not have any employer")
    }
    history.push("/address-edit")
  }

  const getHomeOfficeUsage = () => {
    let estimatedHomeRent =
      new_original_home_allowance != "0.00"
        ? Number(new_original_home_allowance)
        : Number(original_home_allowance)

    let homeOfficeUsage = new_home_allowance != "0.00" ? new_home_allowance : home_allowance

    let home_office_usage_percentage = Number(
      (Number(homeOfficeUsage) * 100) / Number(estimatedHomeRent)
    ).toFixed(2)

    if (address_change_requested) {
      home_office_usage_percentage = new_home_office_usage != 0 ? new_home_office_usage : home_office_usage
      let homeOfficeUsage = new_home_allowance != "0.00" ? new_home_allowance : home_allowance
      homeOfficeUsage = Number(
        (Number(home_office_usage_percentage) * Number(estimatedHomeRent)) / 100
      ).toFixed(2)
    }

    return {
      estimatedHomeRent,
      homeOfficeUsage,
      home_office_usage_percentage
    }
  }

  const styles = {
    addressCon: {
      display: 'flex', justifyContent: 'space-between', margin: "20px 6px",
    },
  }

  return (

    <div className="container dashboard">
      <Row>
        <Col xs={12} sm={12} md={12} >

          <div style={styles.addressCon}>
            <span className="spanTitle">Address</span>
            <span>
              <div onClick={onClickEdit} style={{ cursor: "pointer" }}>
                <img src={EditAddress} />
              </div>
            </span>
          </div>
          <div className="address-container">
            <div className="rows">
              <Row className="first-row">
                <Col sm={12} md={6} lg={4}>
                  <div className="box">
                    <div className="box-title">Country</div>
                    <div className="box-body">{new_country == "" ? country : new_country}</div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                  <div className="box">
                    <div className="box-title">City</div>
                    <div className="box-body">{new_city == "" ? city : new_city}</div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                  <div className="box">
                    <div className="box-title">Home Or Appartment Size</div>
                    <div className="box-body">{new_home_size == "" ? home_size : new_home_size}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={4}>
                  <div className="box">
                    <div className="box-title">Zip Code</div>
                    <div className="box-body">{new_zip_code == "" ? zip_code : new_zip_code}</div>
                  </div>
                </Col>
                <Col sm={12} md={6} lg={4}>
                  <div className="box">
                    <div className="box-title">Street/ Block/ Address</div>
                    <div className="box-body">{new_street == "" ? street : new_street}</div>
                  </div>
                </Col>
                <Col></Col>
              </Row>
              <div className="line"></div>
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <Row className="last-row">
                <Col className="box-hra" xs={12} sm={12} md={4} lg={4}>
                  <div className="home_allowance-text">Estimated Home Rent</div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  {/* <div className={`${address_change_requested ? "pending" : ""}`}>
                {address_change_requested && <span> Pending</span>}
              </div> */}
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <div className="home_allowance">
                    {getHomeOfficeUsage()?.estimatedHomeRent}
                  </div>
                </Col>
                {/* </div> */}
              </Row>
              <Row className="last-row">
                <Col className="box-hra" xs={12} sm={12} md={4} lg={4}>
                  <div className="home_office_usage-text">Home office usage</div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <div className={`${address_change_requested ? "pending" : ""}`}>
                    {address_change_requested && <span> Pending</span>}
                  </div>{" "}
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <div className="home_office_usage">
                    <span>{getHomeOfficeUsage()?.homeOfficeUsage ? `$${getHomeOfficeUsage()?.homeOfficeUsage}` : "$."}</span>
                    <span className="middle text-black">|</span>
                    <span className="text-black">
                      {parseInt(getHomeOfficeUsage()?.home_office_usage_percentage) ? `${getHomeOfficeUsage()?.home_office_usage_percentage}%` : ".%"}
                    </span>
                  </div>{" "}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Address
