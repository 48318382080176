import React from "react"
import AllowanceType from "./AllowanceType"
import Departments from "./Departments"
import Roles from "./Roles"
import { Button, Col, Row } from "react-bootstrap"
import useModal from "../../Context/ModalContext"
import useUser from "../../Context/UserContext"
import {
  createDepartment,
  createRole,
  createAllowanceType
} from "../../Services/api"
import "./manageAllowance.scss"

function ManageAllowance({
  departments,
  roles,
  allowanceType,
  fetchAllowanceType,
  onClickRowHandler,
  setAssign,
  fetchDepartments
}) {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { token, user } = globalState
  const onSaveDepartmentHandler = async (payload, cb) => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }

      const response = await createDepartment(payload, headers)
      fetchDepartments()
      cb()
    } catch (error) {
      console.log(error)
    }
  }

  const onSaveRoleHandler = async (payload, cb) => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const { title, department } = payload
      //  return
      if (department.isNew) {
        // create department
        const res = await createDepartment({ title: department.value }, headers)
        const deptID = res.data.id
        const response = await createRole(
          { title, department: deptID },
          headers
        )
        // create role under department
      } else {
        const response = await createRole(
          { title, department: department.value },
          headers
        )
      }
      fetchDepartments()
      cb()
    } catch (error) {
      console.log(error)
    }
  }

  const onSaveAllowanceTypeHandler = async (payload, cb) => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const response = await createAllowanceType(payload, headers)
      fetchAllowanceType()
      cb()
    } catch (error) {
      console.log(error)
    }
  }

  const getDepartmentOptions = () => {
    return departments.map(({ id, title }) => ({
      value: id,
      label: title
    }))
  }

  return (
    <>
      <div className="container dashboard">
        <Row className="title-wrapper">
          <Col>
            <span className="title">Manage Allowances</span>
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <Button className="assign-btn" onClick={() => setAssign(true)}>
              <span>Assign</span>
            </Button>
          </Col>
        </Row>

        {/* <AssignAllowance /> */}
        <div className="manageAllowance">
          <div className="row">
            <Col xs={12} sm={12} md={6} lg={5}>
              <AllowanceType
                onClickAddHandler={onSaveAllowanceTypeHandler}
                data={allowanceType}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={5}>
              <Departments
                onClickAddHandler={onSaveDepartmentHandler}
                data={departments}
                onClickRowHandler={onClickRowHandler}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={5}>
              <Roles
                onClickAddHandler={onSaveRoleHandler}
                departmentOptions={getDepartmentOptions()}
                data={roles}
              />
            </Col>
          </div>
        </div>
        {/* </Col>

        </Row> */}
      </div>
    </>
  )
}

export default ManageAllowance
