export const initialState = {
  showModal: false
}

const modalReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case "TOGGLE_MODAL":
      return {
        ...state,
        ...payload
      }

    default:
      throw new Error(`No case for type ${type} found in modalReducer.`)
  }
}

export default modalReducer
