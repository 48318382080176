import React from "react"
import { Route } from "react-router-dom"
import userIsLoggedIn from "../../utils/userIsLoggedIn"
import useUser from "../../Context/UserContext"
import { useHistory } from "react-router-dom"
export default function AppRoute({
  component: Component,
  path,
  isPrivate,
  ...props
}) {
  const history = useHistory()
  const {
    state: {
      user: { profile_completed },
      token
    },
    addGoSteps,
    addUserType,
    addUserDetails,
    userLogout
  } = useUser()
  return (
    <Route path={path} render={props => <Component {...props} />} {...props} />
  )
}
