import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Col, Form, Row, Image } from "react-bootstrap"
import "./editMyProfile.scss"
import InputField from "../../../Layouts/InputField"
import Dropdown from "../../../Layouts/Dropdown"
import { updateUser } from "../../../Services/api"
import useUser from "../../../Context/UserContext"
import { notifyError, notifySuccess } from "../../Toast"
import { useHistory } from "react-router-dom"
import DatePicker from "../../../Layouts/DatePicker"

const initialState = { alt: "", src: "" }

function EditMyProfile() {
  const history = useHistory()
  const [{ alt, src }, setPreview] = useState(initialState)
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { addUserDetails } = useUser()
  const { token, user } = globalState
  const { id, name, email, employee } = user
  const {
    dob,
    gender,
    picture,
    phone,
    department,
    role,
    employee_id,
    employer
  } = employee
  const { departments } = employer && employer.employer ? employer.employer : []
  const [roles, setRoles] = useState([])
  const [roleSelected, setRoleSelected] = useState(role)
  const [departmentSelected, setDepartmentSelected] = useState(department)
  const [_dob, setDOB] = useState(null)

  const updateEmployeeDetails = async payload => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const response = await updateUser(user.id, payload, headers)
      notifySuccess("Profile updated successfully")
      addUserDetails({
        user: response.data
      })
      history.push("/profile")
    } catch (error) {
      console.log(error)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: email,
      name: name,
      picture: "",
      phone: phone,
      dob: dob,
      gender: gender,
      employee_id: employee_id
    },
    // validationSchema: Yup.object({
    //   password_1: Yup.string()
    //     .max(15, "Must be 15 characters or less")
    //     .required("Password Required"),

    //   email: Yup.string()
    //     .email("Invalid email address")
    //     .required("Email Required")
    // }),
    onSubmit: values => {
      try {
        const formData = new FormData()
        for (let key in values) {
          if (values[key]) {
            if (key === "name") {
              formData.append(`${key}`, values[key])
            } else {
              formData.append(`employee.${key}`, values[key])
            }
          }
        }
        updateEmployeeDetails(formData)
      } catch (error) {
        if (error.response.status == 500) {
          notifyError("Internal server error")
        }
      }
    }
  })

  const onGenderChangeHandler = e => {
    const { value, checked } = e.target
    setGender(prevOptions => {
      return prevOptions.map(option => {
        if (option.value === value) {
          return { ...option, checked }
        } else {
          return { ...option, checked: false }
        }
      })
    })
  }

  const [genderOptions, setGender] = useState([
    {
      label: "Male",
      value: "Male",
      checked: false
    },
    {
      label: "Female",
      value: "Female",
      checked: false
    },
    {
      label: "Non-binary",
      value: "Non-binary",
      checked: false
    },
    {
      label: "Do not wish to enter",
      value: "",
      checked: false
    }
  ])
  const fileHandler = event => {
    const { files } = event.target
    setPreview(
      files.length
        ? {
          src: URL.createObjectURL(files[0]),
          alt: files[0].name
        }
        : initialState
    )
    formik.setFieldValue("picture", files[0])
  }

  useEffect(() => {
    setGender(prevOptions => {
      return prevOptions.map(option => {
        if (option.value === gender) {
          return { ...option, checked: true }
        } else {
          return { ...option, checked: false }
        }
      })
    })
  }, [gender])

  useEffect(() => {
    if (!_dob) {
      formik.setFieldValue("dob", "")
    }
    if (_dob) {
      const formattedDate = `${_dob.getMonth() + 1}-${_dob.getDate()}-${_dob.getFullYear()}`
      //  `${_dob.getFullYear()}-${_dob.getMonth() + 1}-${_dob.getDate()}`

      formik.setFieldValue("dob", formattedDate)
    }
  }, [_dob])

  useEffect(() => {
    setPreview({
      src: employee.picture,
      alt: ""
    })
  }, [])

  useEffect(() => {
    // dob is coming as yyyy-mm-dd as 2022-07-23, need to convert to mm-dd-yy
    const _dob = new Date(dob)
    const formattedDate = `${_dob.getMonth() + 1} - ${_dob.getDate()} - ${_dob.getFullYear()}`
    setDOB(new Date("03/23/1992"))
  }, [dob])

  const styles = {
    backBtnStyles: {
      objectFit: 'contain',
      position: 'absolute',
      cursor: 'pointer',
    }
  }

  return (
    <div className="container dashboard">
      <div className="container edit-my-profile">
        <Image
          onClick={() => history.goBack()}
          src={require("../../../Assets/images/back-icon.png")}
          width={25}
          height={25}
          style={styles.backBtnStyles}
        />
        <div className="title">
          <span>Edit Personal Details</span>
        </div>
        <div className="box profile-card">
          <div className="img-wrapper">
            <input
              id="profileImage"
              accept="image/*"
              type="file"
              onChange={fileHandler}
            />

            <label for="profileImage">
              <span className="img-cover">
                {src ? (
                  <img className="img-cover preview" src={src} alt={alt} />
                ) : (
                  <i class="far fa-user"></i>
                )}
              </span>
              <div className="camera">
                <i class="far fa-camera"></i>
              </div>
            </label>
          </div>
          <div className="emp-details">
            <span className="name">{user.name}</span>
          </div>
        </div>
        <div className="edit-form">
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col sx={12} sm={12} md={6}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <InputField
                    name="name"
                    type="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    placeholder="Your Name"
                    formik={formik}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email ID</Form.Label>
                  <InputField
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder="Your Email"
                    formik={formik}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="phone">
                  <Form.Label>Mobile Number</Form.Label>
                  <InputField
                    name="phone"
                    type="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    placeholder="Your Email"
                    formik={formik}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-danger">{formik.errors.phone}</div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col sx={12} sm={12} md={6}>
                <Form.Group controlId="gender">
                  <Form.Label>Gender</Form.Label>
                  <div key={`inline-${"type"}`} className="gender">
                    {genderOptions.map(({ label, value, checked }) => (
                      <Form.Check
                        inline
                        label={label}
                        name="gender"
                        type="radio"
                        id={`inline-${label}-1`}
                        onChange={value => {
                          console.log(
                            "onGenderChangeHandler",
                            value.target.value
                          )
                          formik.handleChange("gender")(value.target.value)
                          onGenderChangeHandler(value)
                        }}
                        value={value}
                        checked={checked}
                      />
                    ))}
                  </div>
                </Form.Group>
                <Form.Group controlId="dob" className="dob-field">
                  <Form.Label className="lable-dob">Date of Birth</Form.Label>

                  <DatePicker
                    name="dob"
                    startDate={_dob}
                    setStartDate={dob => {
                      console.log(dob)
                      setDOB(dob)
                    }}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.dob && formik.errors.dob ? (
                      <div className="text-danger">{formik.errors.dob}</div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
                <Form.Group
                  controlId="employee_id"
                  className="employee_id-field"
                >
                  <Form.Label className="lable-employee_id">
                    Employee ID/Number
                  </Form.Label>
                  <InputField
                    name="employee_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.employee_id}
                    placeholder="Employee ID/Number"
                    formik={formik}
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.employee_id && formik.errors.employee_id ? (
                      <div className="text-danger">
                        {formik.errors.employee_id}
                      </div>
                    ) : null}
                  </Form.Text>
                </Form.Group>
              </Col>

              <Col sx={12} sm={12} md={6}>
                <Form.Group>
                  <Button
                    className="update-btn"
                    variant="primary"
                    type="submit"
                    onClick={values => { }}
                  // disabled={formState}
                  >
                    {false ? <span>Loading...</span> : <span>Update</span>}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default EditMyProfile
