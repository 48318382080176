import React, { useState } from "react"
import * as Yup from "yup"
import { Button, Form, Modal } from "react-bootstrap"
import AddIcon from "../../Assets/images/add_icon.png"
import BasicTable from "../Table/BasicTable"
import ModalComponent from "../ModalComponent"
import { useFormik } from "formik"
import Dropdown from "../../Layouts/Dropdown/Dropdown"
import CreatableDropdown from "../CreatableDropdown/CreatableDropdown"

function Roles({ onClickAddHandler, departmentOptions, data }) {
  const [show, setShow] = useState(false)
  const [departmentSelected, setDepartment] = useState(false)

  const formik = useFormik({
    initialValues: {
      title: ""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("name Required")
    }),
    onSubmit: values => {
      onClickAddHandler(
        {
          ...values,
          department: departmentSelected
        },
        () => {
          setShow(false)
        }
      )
    }
  })
  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "psuedoId"
      },
      {
        Header: "Roles",
        accessor: "title"
      }
    ],
    []
  )

  const prepareData = React.useMemo(
    () => data.map((d, index) => ({ ...d, psuedoId: index + 1 })),
    [JSON.stringify(data)]
  )

  return (
    <div className="roles-card column">
      <div className="header">
        <span>Roles</span>
        <Button className="add-icon" onClick={() => setShow(true)}>
          <img src={AddIcon} />
        </Button>
      </div>
      <div className="body">
        <div className="users-table">
          <BasicTable columns={columns} data={prepareData} />
        </div>
      </div>
      <ModalComponent
        className="manage-allowance"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Role</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body className="show-grid">
            <div className="add-wrapper">
              <Form.Group controlId="name">
                <Form.Label>Role Designation</Form.Label>
                <Form.Control
                  name="title"
                  type="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  placeholder="Eg, Human Resource"
                />
                <Form.Text className="text-danger">
                  {formik.touched.title && formik.errors.title ? (
                    <div className="text-danger">{formik.errors.title}</div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="department">
                <Form.Label className="department">
                  Select Department
                </Form.Label>
                <CreatableDropdown
                  name="department"
                  departmentOptions={departmentOptions}
                  setDepartment={setDepartment}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="signIn-btn"
              variant="primary"
              type="submit"
              onClick={values => { }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </ModalComponent>
    </div>
  )
}

export default Roles
