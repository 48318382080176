import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Container, Row, Form, Alert } from "react-bootstrap"
import SplashContent from "../SplashContent"
import { changePassword } from "../../Services/api"
import { Link, useHistory, useLocation } from "react-router-dom"
import "./resetPassword.scss"

function ResetPassword() {
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)
  const token = params.get("token")
  const location = useLocation()
  const [formState, setFormState] = useState(true)
  const [type, setType] = useState({
    password: {
      type: "password"
    },
    confirmPassword: {
      type: "password"
    }
  })
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(undefined)

  const handleEyeClick = name => {
    setType(prevType => {
      return {
        ...prevType,
        [name]:
          prevType[name].type === "password"
            ? {
              type: "text"
            }
            : {
              type: "password"
            }
      }
    })
  }

  const userLogin = async data => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }

      const payload = {
        password_1: data.password,
        password_2: data.confirmPassword
      }
      setLoading(true)
      const response = await changePassword(payload, headers)
      history.push("/login")
    } catch (error) {
      if (error.response) {
        setLoading(false)
        setError(error.response.data.detail)
      }
    }
  }

  // useEffect(() =>
  //   console.log(location)
  //   , [])

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password")], "Passwords must match")
    }),
    onSubmit: values => {
      // console.log("loginDetails", values)
      userLogin(values)
      // setFormState(values);
    }
  })

  // useEffect(() => {
  //   console.log("useLocation ==== ", location)
  // }, [])

  return (
    <div className="landing-page-wrapper">
      <SplashContent>
        <div className="how-to">How to?</div>
        <div className="enter-cred-text">Enter New Password</div>
        <div className="login-form">
          {error && <Alert variant={"danger"}>{error}</Alert>}

          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="password" className="password-field">
              <Form.Label className="lable-password">Password</Form.Label>
              <Form.Control
                name="password"
                type={type["password"].type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                placeholder="Password"
              />
              <Form.Text className="text-danger">
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-danger">{formik.errors.password}</div>
                ) : null}
              </Form.Text>
              <span
                className="eye-icon"
                onClick={() => handleEyeClick("password")}
              >
                {type["password"].type === "text" ? (
                  <i className="far fa-eye"></i>
                ) : (
                  <i className="far fa-eye-slash"></i>
                )}
              </span>
            </Form.Group>
            <Form.Group
              controlId="confirmPassword"
              className="confirmPassword-field"
            >
              <Form.Label className="lable-confirmPassword">
                Confirm Password
              </Form.Label>
              <Form.Control
                name="confirmPassword"
                type={type["confirmPassword"].type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                placeholder="Confirm Password"
              />
              <Form.Text className="text-danger">
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                  <div className="text-danger">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </Form.Text>
              <span
                className="eye-icon"
                onClick={() => handleEyeClick("confirmPassword")}
              >
                {type["confirmPassword"].type === "text" ? (
                  <i className="far fa-eye"></i>
                ) : (
                  <i className="far fa-eye-slash"></i>
                )}
              </span>
            </Form.Group>
            <Button
              className="signIn-btn"
              variant="primary"
              type="submit"
              onClick={values => setFormState(values)}
            // disabled={formState}
            >
              {loading ? <span>Loading...</span> : <span> Reset Password</span>}
            </Button>
          </Form>
        </div>

        <div className="create-account">
          <Link to="/login">Back To Login</Link>
        </div>
      </SplashContent>
    </div>
  )
}

export default ResetPassword
