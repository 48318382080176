import React from "react"
import { Button, Col, Container, Modal, Row } from "react-bootstrap"
import "./modalComponent.scss"

function ModalComponent(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      {props.children}
    </Modal>
  )
}

export default ModalComponent
