import React from "react"
import { createContext, useReducer, useContext } from "react"
import modalReducer, { initialState } from "./modalReducer"

const ModalContext = createContext(initialState)

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(modalReducer, initialState)

  const toggleModal = payload => {
    dispatch({
      type: "TOGGLE_MODAL",
      payload: {
        showModal: payload
      }
    })
  }

  const value = {
    state,
    toggleModal
  }

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

const useModal = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error("useModal must be used within ModalContext")
  }
  const {
    state: { showModal },
    toggleModal
  } = context
  return [showModal, toggleModal]
}

export default useModal
