import React from "react"
import { Select } from "react-select-virtualized"
import { City } from "country-state-city"

const optionsDefault = City.getCitiesOfCountry("US").map(city => ({
  value: `${city.name}`,
  label: `${city.name}`
}))

export default function (props) {
  return (
    <div className="dropdown-virtualized">
      <Select options={optionsDefault} {...props} />
    </div>
  )
}
