import React, { useState, useEffect } from "react"
import { Button, Form } from "react-bootstrap"
import EditIcon from "../../Assets/images/edit.png"
import Address from "../../Assets/images/address.png"
import "./employeeDetails.scss"
function ApprovedHRACard({
  title,
  street,
  city,
  country,
  zip_code,
  home_size,
  home_allowance,
  original_home_allowance,
  assigned_home_allowance,
  home_office_usage,
  electricity_bill,
  internet_bill,
  home_office_furniture_bill,
  formikHRA,
  address_change_requested
}) {
  const [editMode, setEditMode] = useState(address_change_requested)
  const [proposedHRA, setPerposedHRA] = useState(0)
  const [proposedPercentage, setPerposedPercentage] = useState(0)
  const [assignedHRA, setAssignedHRA] = useState(0)
  const [assignedPercentage, setAssignedPercentage] = useState(0)

  // useEffect(() => {
  //   console.log("electricity_bill === ", parseInt(electricity_bill));
  // }, [electricity_bill])

  const onChangeHandle = e => {
    const { value } = e.target
    const result = Number(
      (100 * Number(value)) / Number(original_home_allowance)
    ).toFixed(2)
    setAssignedHRA(value)
    formikHRA.setFieldValue("allowance", value)
    if (!original_home_allowance) return
    setAssignedPercentage(result)

  }

  const calculateProposedHRA = () => {
    const proposedHRA = Number(
      (Number(original_home_allowance) * Number(home_office_usage)) / 100
    ).toFixed(2)
    if (address_change_requested) {
      setAssignedHRA(proposedHRA)
      setAssignedPercentage(home_office_usage)
      formikHRA.setFieldValue("allowance", proposedHRA)
    }
    setPerposedHRA(proposedHRA)
    setPerposedPercentage(home_office_usage)
  }

  const calculateAssignedHRA = () => {
    if (Number(original_home_allowance) > 0) {
      const proposedPercentage = Number(
        (Number(assigned_home_allowance) * 100) /
        Number(original_home_allowance)
      ).toFixed(2)
      setAssignedHRA(assigned_home_allowance)
      setAssignedPercentage(proposedPercentage)
    }
  }

  useEffect(() => {
    calculateProposedHRA()
  }, [home_office_usage])

  useEffect(() => {
    if (!address_change_requested) {
      calculateAssignedHRA()
    }
  }, [assigned_home_allowance])

  return (
    <div className="p-card address">
      <Form
        onSubmit={e => {
          e.preventDefault()
          formikHRA.handleSubmit()
          setEditMode(false)
        }}
      >
        <div className="header">
          <div style={{ display: 'flex', }} className="title">
            <span className="icon">
              <img src={Address} />
            </span>
            <span style={{ display: 'flex', }} className="text">{title}</span>
          </div>
          {editMode ? (
            <Button type="submit" className="approve-btn" onClick={() => { }}>
              Approve
            </Button>
          ) : (
            <div className="edit-hra" onClick={() => setEditMode(true)}>
              <img src={EditIcon} />
            </div>
          )}
        </div>
        <div className="line"></div>

        <div className="body">

          <div className="new-hra-box">
            <span className="address">{`${street} ${city} ${country} ${zip_code}`}</span>
          </div>

          <div className="new-hra-box">
            <span className="new-hra-box-title">Home Or Appartment Size</span>
            <span className="new-hra-box-hra">
              {home_size ? `${home_size}` : "--"}
            </span>
          </div>

          <div className="new-hra-box assigned-hra2">
            <span className="new-hra-box-title">Estimated Home Rent</span>

            <span className="new-hra-box-hra ml-auto">
              {original_home_allowance ? `$${original_home_allowance}` : "--"}
            </span>

            <span className="new-hra-box-hra">
              {original_home_allowance ? `100%` : "--"}
            </span>
          </div>

          <div className="new-hra-box assigned-hra2">
            <span className="new-hra-box-title">
              Home Office usage Proposal
            </span>
            <span className="new-hra-box-hra ml-auto">
              {proposedHRA ? `$${proposedHRA}` : "--"}
            </span>
            <span className="new-hra-box-hra">
              {proposedPercentage ? `${parseInt(proposedPercentage)}%` : "--"}
            </span>
          </div>
          <div className="new-hra-box assigned-hra">
            <span className="new-hra-box-title">
              Assigned home office allowance
            </span>
            {editMode ? (
              <span className="new-hra-box-hra">
                <Form.Control
                  name="home_allowance"
                  type="home_allowance"
                  className="home_allowance"
                  onChange={onChangeHandle}
                  onBlur={formikHRA.handleBlur}
                  value={assignedHRA}
                  placeholder=""
                />
                <i
                  class="far fa-times"
                  onClick={() => {
                    if (address_change_requested) {
                      calculateProposedHRA()
                    } else {
                      calculateAssignedHRA()
                    }
                    setEditMode(false)
                  }}
                ></i>
              </span>
            ) : (
              <span className="new-hra-box-hra ml-auto">
                {assignedHRA ? `$${assignedHRA}` : "--"}
              </span>
            )}

            <span className="new-hra-box-hra">
              {assignedPercentage ? `${parseInt(assignedPercentage)}%` : "--"}
            </span>
          </div>
          <div className="new-hra-box">
            <span className="new-hra-box-title">Internet Bill Monthly</span>
            <span className="new-hra-box-hra ml-auto"></span>
            <span className="new-hra-box-hra">
              {internet_bill ? `$${internet_bill}` : "--"}
            </span>
          </div>
          <div className="new-hra-box">
            <span className="new-hra-box-title">Electricity Bill Monthly</span>
            <span className="new-hra-box-hra ml-auto"></span>
            <span className="new-hra-box-hra">
              {electricity_bill ? `$${electricity_bill}` : "--"}
            </span>
          </div>
          <div className="new-hra-box">
            <span className="new-hra-box-title">
              Home Office Furniture Monthly
            </span>
            <span className="new-hra-box-hra ml-auto"></span>
            <span className="new-hra-box-hra">
              {home_office_furniture_bill
                ? `$${home_office_furniture_bill}`
                : "--"}
            </span>
          </div>
        </div>
        <div className="footer"></div>
      </Form>
    </div>
  )
}

const styles = {
  hraBox: { display: 'flex', paddin: 8, width: "100%", },
  hraBoxTitle: { paddingLeft: 18, width: '65%', backgroundColor: 'yellow', flexWrap: 'wrap', display: 'flex' },
  hraBoxHra: { width: 80, backgroundColor: 'green' },
  hraBoxPercen: { backgroundColor: 'blue', width: 50, },
}

export default ApprovedHRACard
