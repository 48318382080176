import React from "react"
import ReactDom from "react-dom"
import { UserProvider } from "./Context/UserContext"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


// if ("serviceWorker" in navigator) {
//   let swPath = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`

//   if (process.env.NODE_ENV === "production") {
//     console.log("serviceWorker in production", process.env)
//     swPath = "./firebase-messaging-sw.js"
//   }
//   console.log("serviceWorker in production swPath", swPath)

//   navigator.serviceWorker
//     .register(swPath)
//     .then(function (registration) {
//       console.log("Registration successful, scope is:", registration.scope)
//     })
//     .catch(function (err) {
//       console.log("Service worker registration failed, error:", err)
//     })
// }

ReactDom.render(
  <UserProvider>
    <Router>
      <App />
    </Router>
  </UserProvider>,
  document.getElementById("root")
)

serviceWorkerRegistration.register();