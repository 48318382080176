import React from "react"
import { useHistory } from "react-router-dom"
import "./notifications.scss"

function Notifications({
  loading,
  notifications,
  goToEmployeeDetails,
  onCloseNotificationHandle
}) {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { user } = globalState
  const history = useHistory()

  const listNotifications = () => {
    return notifications.map(notification => {
      const { data, id, read, type, content } = notification
      return (
        <div
          class={`notifications-item ${read ? "read" : "not-read"}`}
          onClick={() => {
            onCloseNotificationHandle()
            // if (read && user.type === "Employer") {
            //   history.push("/invite/employee-details", {
            //     userId: data
            //   })
            //   return
            // }
            goToEmployeeDetails(data, id, type)
          }}
        >
          <div class="text">
            {/* <h4>Samso aliao</h4> */}
            <p>{content}</p>
          </div>
        </div>
      )
    })
  }

  return (
    <div class="notifications" id="notifications-box">
      <p className="header">Notifications</p>
      {loading ? <h5>Loading...</h5> : listNotifications()}
    </div>
  )
}

export default Notifications
