import React from "react"
import { useTable } from "react-table"
import "./table.scss"

function Table({ columns, data, onClickRowHandler, loading }) {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { departmentSelected } = globalState
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    })

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {loading ? (
          <h3>Loading...</h3>
        ) : (
          rows.map((row, i) => {
            prepareRow(row)

            return (
              <tr
                {...row.getRowProps()}
                onClick={() =>
                  typeof onClickRowHandler == "function"
                    ? onClickRowHandler(row.original)
                    : ""
                }
                className={`${
                  departmentSelected && departmentSelected.id && row.original.id === departmentSelected.id
                    ? "active-department"
                    : ""
                }`}
              >
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                })}
              </tr>
            )
          })
        )}
      </tbody>
    </table>
  )
}

function BasicTable({ columns, data, onClickRowHandler, loading }) {
  return (
    <Table
      columns={columns ? columns : []}
      data={data ? data : []}
      onClickRowHandler={onClickRowHandler}
      loading={loading}
    />
  )
}

export default BasicTable
