import React from "react"
import { Route, Redirect } from "react-router-dom"

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props => {
        const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
        const { user, token } = globalState || {
          user: {
            profile_completed: false
          },
          token: ""
        }

        if (!user.profile_completed && !token) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        if (!user.profile_completed) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        return <Component {...props} />
      }}
    />
  )
}
