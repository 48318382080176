import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  getRequestTNC,
  getRequestWPayload
} from "./axiosClient"

export const login = payload => postRequest("users/login/", payload)
export const otp = payload => postRequest("users/otp/", payload)
export const verify = payload => postRequest("users/verify/", payload)
export const changePassword = (payload, headers) =>
  postRequest("users/password/", payload, headers)
export const getUser = (userId, headers) =>
  getRequest(`users/${userId}/`, headers)
export const addUser = payload => postRequest(`users/`, payload)
export const updateUser = (userId, payload, headers) =>
  patchRequest(`users/${userId}/`, payload, headers)
export const createDepartment = (payload, headers) =>
  postRequest("departments/", payload, headers)
export const getDepartment = config => getRequest(`departments/`, config)
export const getEmployeesCSV = token => getRequestWPayload(`users/csv/`, token) // ===== get employees csv data =====
export const createRole = (payload, headers) =>
  postRequest("roles/", payload, headers)
export const createAllowanceType = (payload, headers) =>
  postRequest("allowances/type/", payload, headers)
export const getAllowanceType = headers =>
  getRequest("allowances/type/", headers)
export const getAllowances = headers => getRequest("allowances/", headers)
export const createAllowance = (payload, headers) =>
  postRequest("allowances/", payload, headers)
export const getEmployeesUnderAllowances = headers =>
  getRequest("allowances/employees/", headers)
export const deleteAllowance = (id, headers) =>
  deleteRequest(`allowances/${id}/`, headers)
export const updateAllowance = (id, payload, headers) =>
  patchRequest(`allowances/${id}/`, payload, headers)
export const employeeInvite = (payload, headers) =>
  postRequest("invitations/", payload, headers)
export const getAllEmployers = (query, headers) =>
  getRequest(`employers/?industry=${query || ""}`, headers)
export const getIndustries = () => getRequest("industries/")
export const getchangeEmployerRequest = headers =>
  getRequest("change-employer/", headers)
export const changeEmployer = (payload, headers) =>
  postRequest("change-employer/", payload, headers)
export const getEmployees = (query, headers) =>
  getRequest(`employees/internal/?search=${query}`, headers)
export const approveEmployee = (id, payload, headers) =>
  patchRequest(`change-employer/${id}/`, payload, headers)
export const cancelEmployee = (id, headers, payload) =>
  deleteRequest(`change-employer/${id}/`, payload, headers)
export const getBilledAllowances = (query, headers) =>
  getRequest(`billed-allowances/?${query}`, headers)
export const getBilledAllowanceUsers = (query, headers) =>
  getRequest(`billed-allowances/users/?${query}`, headers)
export const reomoveAttachment = (payload, headers) =>
  deleteRequest(`employees/attachment/`, headers, payload)
export const getNotifications = headers => getRequest("notifications/", headers)
export const getEmployeeBilledAllowances = (query, headers) =>
  getRequest(`billed-allowances/report/?${query}`, headers)
export const releaseEmployee = (payload, headers) =>
  postRequest("employees/release/", payload, headers)
export const approveAddressChange = (payload, headers) =>
  postRequest("employees/approve/", payload, headers)
export const updateHomeAllowance = (payload, headers) =>
  postRequest("employees/allowance/", payload, headers)
export const uploadCSV = (payload, headers) =>
  postRequest("invitations/csv/", payload, headers)
export const markNotificationRead = (payload, headers) =>
  postRequest("notifications/read/", payload, headers)
export const getTNC = () =>
  getRequestTNC("https://tandem-30128.botics.co/modules/terms-and-conditions/")
