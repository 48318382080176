import React, { useState, useEffect } from "react"
import { Col, Row } from "react-bootstrap"
import { getTNC } from "../../../Services/api"
import "./TermsAndConditions.scss"

export default function TermsAndConditions() {
  const [loading, setLoading] = useState(true)
  const [tnc, setTNC] = useState("")
  useEffect(() => {
    getTNC()
      .then(response => {
        setTNC(response.data?.[0]?.body)
        setLoading(false)
      })
      .catch(error => console.log("terms and conditions error", error))
  }, [])
  return (
    <div className="container dashboard">
      <Row>
        <Col xs={12} sm={12} md={12}>
          <div className="terms-n-conditions">
            <div className="title-wrapper">
              <p className="title">Terms And Conditions</p>
            </div>
            <p className="body">{loading ? "Loading..." : tnc}</p>
            {/* <p className="agreements">Agreements</p>
        <p className="agreements-details">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames
          venenatis ut volutpat, velit laoreet aliquam scelerisque. Placerat
          arcu, egestas vulputate euismod vitae, sed nulla a sit. Faucibus
          dignissim sit semper imperdiet mi volutpat id. Elit, orci ut nisl
          bibendum viverra nascetur. Viverra at fusce id ut velit sit fermentum
          odio. Mi orci, sed amet, amet at eget ac pulvinar nunc. In ut viverra
          aliquam ac ornare. Dui purus porttitor id feugiat semper. Quis tortor
          aliquet turpis duis faucibus orci tempor. Egestas consectetur ut lacus
          in viverra. Sit et tempus est volutpat nisl etiam. Suspendisse amet,
          eget morbi et, penatibus velit pulvinar. Consectetur bibendum ac
          gravida eget metus. Metus lacus, proin augue dapibus sapien fermentum
          diam iaculis eget. Platea condimentum vitae egestas at cursus magnis
          faucibus. Bibendum magna vel volutpat tempor enim ultricies sodales
          odio. Magna arcu molestie pretium, tristique sapien ullamcorper elit.
          Ac adipiscing sed eu placerat pellentesque. Accumsan, non sit
          elementum nunc sit faucibus. Consectetur eget elit nunc fermentum odio
          mollis ut neque. Felis hac viverra mattis sagittis, sodales tortor.
          Lectus est volutpat felis potenti. Ac sagittis semper massa donec
          lectus commodo scelerisque justo adipiscing. Orci sit dictum habitasse
          viverra nec nibh sed ullamcorper elementum. Aenean ultrices sed nisi,
          congue massa quam. Donec amet integer vivamus turpis in volutpat. Dui
          in tempus lacus convallis. Volutpat proin elementum nunc, ac, velit
          ante fames. Facilisis cras ultrices blandit habitant consequat. Dui in
          nulla aliquam justo, pharetra, elit. Tempor elit a donec aenean
          maecenas senectus quisque. Egestas accumsan sagittis lacus, tempor
          aliquet habitant nullam. Volutpat nibh urna dignissim pellentesque in.
          Sed lorem cursus facilisis turpis platea. Ipsum condimentum bibendum
          amet ornare enim. Vestibulum sed convallis viverra pulvinar. A enim
          amet in felis pharetra porta pellentesque consequat nulla. Varius
          aliquam pellentesque odio accumsan, vel gravida orci. Leo, tellus
          commodo lorem velit venenatis, id cras donec enim. A nec eget ipsum
          cursus phasellus venenatis diam dui. Sagittis lectus metus quis augue
          quam tincidunt. Aliquam orci luctus neque nibh. Condimentum nulla
          aenean nisl risus dictum euismod semper. Aliquet ornare nisl nunc
          facilisis nisi morbi non quam vestibulum. Euismod lorem risus, tempor
          at lorem aliquam auctor enim. Quam magna mattis viverra vel in tortor
          amet. Iaculis nulla duis eu id. Laoreet erat ultricies interdum
          interdum. Nunc varius massa adipiscing neque. Nunc lorem ac neque, ac.
          Habitant risus consectetur tincidunt ullamcorper. Volutpat metus, vel
          gravida faucibus. Ut orci, faucibus tortor auctor. Donec vitae
          eleifend id sagittis. Ut viverra at lectus rhoncus magnis. Orci
          pretium, id aliquam aliquam ipsum tristique vel tellus penatibus.
          Porta elementum ipsum ac mollis augue purus, facilisis dolor. Mauris,
          cras est risus arcu tellus iaculis feugiat. Nisi bibendum morbi
          ullamcorper nulla.
        </p> */}
          </div>
        </Col>
      </Row>
    </div>
  )
}
