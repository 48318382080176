import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Container, Row, Form } from "react-bootstrap"
import { Link } from "react-router-dom"
import { login } from "../../Services/api"
import { useHistory } from "react-router-dom"
import useUser from "../../Context/UserContext"
import Dropdown from "../../Layouts/Dropdown"

function NewRequestForm({ inviteEmployeeHandle, departments }) {
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      department: "",
      role: "",
      employee_id: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      department: Yup.string().required("Please select department"),
      role: Yup.string().required("Please select start date"),
      employee_id: Yup.string().required("Please select end date")
    }),
    onSubmit: values => {
      console.log(values)
      setLoading(true)
      inviteEmployeeHandle(values, status => {
        if (status === "success") {
          formik.resetForm()
          formik.setFieldValue("department", "")
          formik.setFieldValue("role", "")
        }
        setLoading(false)
      })
    }
  })

  return (
    <div className="invite-form">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Group controlId="name">
          <Form.Control
            name="name"
            type="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            placeholder="Name"
          />
          <Form.Text className="text-danger">
            {formik.touched.name && formik.errors.name ? (
              <div className="text-danger">{formik.errors.name}</div>
            ) : null}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Control
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder="Email"
          />
          <Form.Text className="text-danger">
            {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="department">
          <Dropdown
            name="department"
            width="363px"
            options={departments}
            getOptionLabel={option => option.title}
            getOptionValue={option => option.id}
            placeholder="Department"
            onChangeHandler={option => {
              setRoles(option.roles)
              formik.handleChange("department")(option.id)
            }}
            onBlur={formik.handleBlur}
            // value={formik.values.department}
          />

          <Form.Text className="text-danger">
            {formik.touched.department && formik.errors.department ? (
              <div className="text-danger">{formik.errors.department}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="role">
          <Dropdown
            name="role"
            width="363px"
            options={roles}
            // value={value}
            getOptionLabel={option => option.title}
            getOptionValue={option => option.id}
            placeholder="Role"
            onChangeHandler={option => formik.handleChange("role")(option.id)}
            onBlur={formik.handleBlur}
            // value={formik.values.role}
          />

          <Form.Text className="text-danger">
            {formik.touched.role && formik.errors.role ? (
              <div className="text-danger">{formik.errors.role}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="employee_id">
          <Form.Control
            name="employee_id"
            type="employee_id"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.employee_id}
            placeholder="Employee ID"
          />
          <Form.Text className="text-danger">
            {formik.touched.employee_id && formik.errors.employee_id ? (
              <div className="text-danger">{formik.errors.employee_id}</div>
            ) : null}
          </Form.Text>
        </Form.Group>

        <div className="invite">
          <Button
            className="invite-btn"
            variant="primary"
            type="submit"
            onClick={values => {}}
            disabled={loading}
          >
            {loading ? <span>Loading...</span> : <span>Invite</span>}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default NewRequestForm
