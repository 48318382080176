import React, { useState, useRef, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Button, Container, Row, Form } from "react-bootstrap"
import SplashContent from "../SplashContent"
import useUser from "../../Context/UserContext"
import { useHistory } from "react-router-dom"
import usePersistedState from "../../hooks/usePersistedState"
import UserType from "./UserType"
import CompanyDetails from "../CompanyDetails"
import EmployeeSignup from "../EmployeeSignup"
import { addUser, updateUser, getAllEmployers } from "../../Services/api"

import "./signup.scss"

function Signup() {
  const history = useHistory()
  const {
    state: { token, user },
    addGoSteps,
    addUserType,
    addUserDetails
  } = useUser()

  const [employers, setEmployers] = useState([])
  const [error, setError] = useState(undefined)
  const [companyName, setCompanyName] = useState("")

  const createUser = async ({ email, password }) => {
    const headers = {
      Authorization: `Token ${token}`
    }
    try {
      const payload = {
        email,
        password,
        type: user.type
      }
      const response = await addUser(payload)
      addUserDetails({
        ...response.data
      })
      addGoSteps({
        goSteps: 1
      })
      // console.log(payload.data)
    } catch (error) {
      if (error.response) {
        setError("user with this Email of User already exists. Please login.")
      }
    }
  }

  const updateCompanyDetails = async (payload, cb) => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const response = await updateUser(user.id, payload, headers)
      // console.log(response.data)
      if (response.data.employer && response.data.employer.company_name) {
        setCompanyName(response.data.employer.company_name)
      }
      addGoSteps({
        goSteps: 3
      })
      addUserDetails({})
    } catch (error) {
      if (error.response.status == 400 && typeof cb == "function") {
        cb(error.response.data)
      }
      console.log(error)
    }
  }

  const updateEmployeeDetails = async payload => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const response = await updateUser(user.id, payload, headers)
      // console.log(response.data)
      addGoSteps({
        goSteps: 3
      })

      addUserDetails({})
    } catch (error) {
      console.log("Error while updating employee details", error)
    }
  }

  const fetchEmployers = async () => {
    try {
      const response = await getAllEmployers()
      const nonEmployerOption = {
        id: null,
        name: "I don't have an Employer now"
      }
      const { data } = response || []
      const filterOption = data.filter(d => d.name !== null)
      setEmployers([nonEmployerOption, ...filterOption])
    } catch (error) { }
  }
  useEffect(() => {
    fetchEmployers()
  }, [])

  const renderSignup = type => {
    switch (type) {
      case "Employer":
        return (
          <CompanyDetails
            updateCompanyDetails={updateCompanyDetails}
            createUser={createUser}
            error={error}
            addUserType={addUserType}
            companyName={companyName}
          />
        )
      case "Employee":
        return (
          <EmployeeSignup
            updateEmployeeDetails={updateEmployeeDetails}
            createUser={createUser}
            addUserType={addUserType}
            employers={employers}
          />
        )

      default:
        return <UserType setUserType={addUserType} />
    }
  }

  return <SplashContent>{renderSignup(user.type)}</SplashContent>
}

export default Signup
