export const HOME_SIZE_OPTIONS = [
  {
    value: "1 Bedroom",
    label: "1 Bedroom"
  },
  {
    value: "2 Bedrooms",
    label: "2 Bedrooms"
  },
  {
    value: "3 Bedrooms",
    label: "3 Bedrooms"
  },
  {
    value: "4+ Bedrooms",
    label: "4+ Bedrooms"
  }
]
