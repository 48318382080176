import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap"
import Dropdown from "../../Layouts/Dropdown"
import BasicTable from "../Table/BasicTable"
import SearchIcon from "../../Assets/images/search.png"
import NewRequestForm from "./NewRequestForm"
import FileUpload from "../../Assets/images/file_upload.png"
import Download from "../../Assets/images/download.png"
import ModalComponent from "../ModalComponent"
import {
  employeeInvite,
  getDepartment,
  getchangeEmployerRequest,
  getEmployees,
  approveEmployee,
  uploadCSV
} from "../../Services/api"
import { useHistory } from "react-router-dom"
import { notifyError, notifySuccess } from "../Toast"
import { useFormik } from "formik"
import "./analytics.scss"
import csvFilePath from "../../Assets/csv/InviteEmployees.csv"

function Analytics() {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { token, user } = globalState
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [employees, setEmployees] = useState([])
  const [departments, setDepartments] = useState([])
  const [deptsSelected, setDeptsSelected] = useState([])
  const [roleOptions, setRoleOptions] = useState([])
  const [rolesSelected, setRolesSelected] = useState([])
  const [tableData, setTableData] = useState([])
  const [employeeSelected, setEmployeeSelected] = useState({})
  const [employeesRequest, setEmployeesRequest] = useState([])

  const formik = useFormik({
    initialValues: {
      search: "",
      role: "",
      department: ""
    },
    onSubmit: values => {
      const { search, role, department } = values
      const query = `${search}&role=${role}&department=${department}`
      fetchEmployees(query)
    }
  })

  const fetchEmployees = async query => {
    try {
      const config = {
        headers: { Authorization: `Token ${token}` }
      }
      setLoading(true)
      const response = await getEmployees(query, config)
      setEmployees(response.data)
      setLoading(false)
    } catch (error) { }
  }

  const showEmployeeProfile = profile => {
    // localStorage.setItem("currentEmployeeView", JSON.stringify(profile))
    // history.push({
    //   pathname: "/invite/employee-view",
    //   state: { data: profile }
    // })
    history.push("/invite/employee-details", {
      userId: profile?.user?.id
    })
  }

  const inviteEmployeeHandle = async (payload, cb) => {
    const headers = { Authorization: `Token ${token}` }
    try {
      const response = await employeeInvite(payload, headers)
      const message = `Invitation sent to ${response.data.name}`
      notifySuccess(message)
      cb("success")
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        const { detail } = error.response.data
        notifyError(detail)
        cb("fail")
      }
    }
  }

  const fetchchangeEmployerRequest = async () => {
    const headers = { Authorization: `Token ${token}` }
    try {
      const response = await getchangeEmployerRequest({ headers })
      setEmployeesRequest(response.data)
    } catch (error) { }
  }

  const approveEmployeeHandle = async (id, value) => {
    try {
      const headers = { Authorization: `Token ${token}` }
      const payload = {
        approved: value
      }
      const response = await approveEmployee(id, payload, headers)
      notifySuccess(response.data.detail)
      fetchEmployees("")
      fetchchangeEmployerRequest()
    } catch (error) {
      notifyError(error.response.detail)
    }
  }

  const fetchDepartments = async () => {
    try {
      const config = {
        headers: { Authorization: `Token ${token}` },
        params: {
          employer: user.id
        }
      }
      const response = await getDepartment(config)
      setDepartments(response.data)
    } catch (error) { }
  }

  useEffect(() => {
    fetchDepartments()
    fetchEmployees("")
    fetchchangeEmployerRequest()
  }, [])

  useEffect(() => {
    setRoleOptions(deptsSelected.roles)
  }, [deptsSelected])

  useEffect(() => {
    if (employees) {
      const data = employees.map((employee, index) => {
        return {
          psuedoId: index + 1,
          ...employee
        }
      })
      setTableData(data)
    }
  }, [JSON.stringify(employees)])

  const renderRequestCards = () => {
    return employeesRequest.map(
      ({ id, approved, employee, role, department }) => {
        return (
          <div className="request-card">
            <div className="header">
              <span>Employee Request</span>
            </div>
            <div className="body">
              <div className="profile">
                <div className="image">
                  <img src={employee?.employee?.picture} alt="" />
                </div>
                <div className="details">
                  <a
                    href=""
                    className="name"
                    onClick={() =>
                      showEmployeeProfile({
                        ...employee.employee,
                        user: {
                          name: employee?.name,
                          email: employee?.email,
                          approvalId: id,
                          id: employee?.id
                        },
                        approved,
                        showApproveBtn: true
                      })
                    }
                  >
                    {employee?.name}
                  </a>
                  <span>{role.title}</span>
                  <span>{department.title}</span>
                  <span>Employee ID {employee?.employee?.employee_id}</span>
                </div>
              </div>
              <div className="action-btn">
                <Button
                  className="cancel"
                  onClick={() => approveEmployeeHandle(id, "False")}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  className="approve"
                  onClick={() => approveEmployeeHandle(id, "True")}
                >
                  <span>Approve</span>
                </Button>
              </div>
            </div>
          </div>
        )
      }
    )
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "psuedoId"
      },
      {
        Header: "Name",
        accessor: row => {
          const { user } = row
          if (user?.id) {
            return user.name
          }
          return ""
        }
      },
      {
        Header: "Role",
        accessor: row => {
          const { role } = row
          if (role?.id) {
            return role.title
          }
          return ""
        }
      },
      {
        Header: "Department",
        accessor: row => {
          const { department } = row
          if (department?.id) {
            return department.title
          }
          return ""
        }
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: cell => (
          <Button
            className="view"
            onClick={() => showEmployeeProfile(cell.row.original)}
          >
            View
          </Button>
        )
      }
    ],
    []
  )

  const data = React.useMemo(
    () => [
      {
        id: "101",
        name: "John Smith",
        role: "Project Manager"
      }
    ],
    []
  )

  return (
    <div className="dashboard container">
      <div className="analytics-container">
        <Row>
          <Col sm={12} md={12} lg={6} xl={7} className="analytics-table">
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <div className="search-box">
                  <label for="search">
                    <img src={SearchIcon} />
                  </label>
                  <input
                    id="search"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    placeholder="Search Here..."
                  />
                </div>
              </Row>
              <Row className="btns-wrapper">
                <Col style={{ marginBottom: "1rem" }}>
                  <Dropdown
                    name="department"
                    width="100%"
                    options={departments}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    placeholder="Select Dept."
                    onChangeHandler={dept => {
                      if (!dept) {
                        setDeptsSelected([])
                        setRolesSelected([])

                        formik.handleChange("department")("")
                        formik.handleChange("role")("")

                        return
                      }
                      formik.handleChange("department")(dept.title)
                      setDeptsSelected(dept)
                    }}
                    onBlur={formik.handleBlur}
                    // isMulti
                    isClearable
                  />
                </Col>
                <Col style={{ marginBottom: "1rem" }}>
                  <Dropdown
                    name="role"
                    width="100%"
                    value={rolesSelected}
                    options={roleOptions}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    placeholder="Select Role"
                    onChangeHandler={role => {
                      if (!role) {
                        formik.handleChange("role")("")
                        return
                      }
                      formik.handleChange("role")(role.title)
                      setRolesSelected(role)
                    }}
                    onBlur={formik.handleBlur}
                    // isMulti
                    isClearable
                  />
                </Col>
                <Col style={{ marginBottom: "1rem" }}>
                  <Button
                    className="go-btn"
                    variant="primary"
                    type="submit"
                    onClick={values => { }}
                  // disabled={formState}
                  >
                    {false ? <span>Loading...</span> : <span>Go</span>}
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="analytics users-table">
                  <BasicTable
                    columns={columns}
                    data={tableData}
                    loading={loading}
                  />
                </div>
              </Row>
            </Form>
          </Col>
          <Col sm={12} md={12} lg={6} xl={5}>
            <div className="employee-invite">
              <div className="invite-new-employee">
                <p>Invite New Employee</p>
                <div className="inputs">
                  <NewRequestForm
                    inviteEmployeeHandle={inviteEmployeeHandle}
                    departments={departments}
                  />
                </div>
                <div className="import">
                  <p>Import Employee</p>
                  <Button className="import-btn" onClick={() => setShow(true)}>
                    <span>Import</span>
                  </Button>
                </div>
              </div>
              <div className="request-cards">{renderRequestCards()}</div>
            </div>
          </Col>
        </Row>
        {show && (
          <FileUploadModal show={show} setShow={setShow} token={token} />
        )}
      </div>
    </div>
  )
}

const FileUploadModal = ({ show, setShow, token }) => {
  const [fileSelected, setFile] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const uploadFile = async () => {
    const headers = { Authorization: `Token ${token}` }

    try {
      if (fileSelected) {
        setLoading(true)
        const formData = new FormData()
        formData.append("file", fileSelected)
        const response = await uploadCSV(formData, headers)
        if (response.status == 200) {
          notifySuccess("File uploaded successfully")
        }
        setLoading(false)
      }
    } catch (error) {
      console.log("uploadFile error", error)
      setLoading(false)
      if (error.response.status === 500) {
        setError("Server Error 500")
        notifyError("Server Error 500")
      }
    }
  }
  return (
    <ModalComponent
      modalTitle="Import Employee"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Role</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <div className="file-wrapper">
          <div className="file-name-container">
            <span className="filename">{fileSelected?.name}</span>
            {loading && <span className="uploading-text">uploading...</span>}
            {error && (
              <span className="error-text" style={{ color: "red" }}>
                {error}
              </span>
            )}
          </div>

          <div style={{ display: 'flex', }}>
            <div className="image-upload">
              <label for="file-input">
                <img src={FileUpload} />
                <span>Upload</span>
              </label>

              <input
                id="file-input"
                type="file"
                onChange={e => {
                  setFile(e.target.files[0])
                }}
              />
            </div>

            <div className="download-csv">
              <label>
                <Image width={22} height={22} src={Download} />
                <a href={csvFilePath} download="Invite Employees.csv"
                >Download Template</a>
              </label>
            </div>

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="signIn-btn" variant="primary" onClick={uploadFile}>
          Save
        </Button>
      </Modal.Footer>
    </ModalComponent>
  )
}

export default Analytics
