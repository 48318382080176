import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import useUser from "../../Context/UserContext"
import Dropdown from "../../Layouts/Dropdown/Dropdown"
import AllowancesTable from "./AllowancesTable"
import {
  createAllowance,
  deleteAllowance,
  updateAllowance
} from "../../Services/api"
import "./allowancesTable.scss"

function AssignAllowance({
  departments,
  allowanceType,
  allowancesData,
  total,
  setAssign,
  fetchAllowances
}) {
  const globalState = JSON.parse(localStorage.getItem("GLOBAL_STATE"))
  const { token, user } = globalState
  const [loading, setLoading] = useState(false)
  const [deptsSelected, setDeptsSelected] = useState([])
  const [roleOptions, setRoleOptions] = useState([])
  const [rolesSelected, setRolesSelected] = useState([])
  const [allowances, setAllowances] = useState(allowancesData)
  const [totalAmount, setTotalAmount] = useState({
    price__sum: 0
  })
  const [filterObj, setFilterObj] = useState([
    {
      department: "",
      role: "",
      type: "",
      frequency: ""
    }
  ])

  const updateAllowanceData = (rowIndex, columnId, value, isEditing) => {
    // We also turn on the flag to not reset the page
    // setSkipPageReset(true)
    setAllowances(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
            isEditing
          }
        }
        return row
      })
    )
  }

  const onClickGreyCheck = async index => {
    const payload = {
      department: "",
      type: "",
      role: "",
      frequency: "",
      price: "400"
    }
    try {
      const filterData = allowances[index]
      payload.department = filterData.department.id
      payload.role = filterData.role.id
      payload.type = filterData.type.id
      payload.frequency = filterData.frequency
      payload.price = filterData.price
      const bool = Object.keys(payload).every(key => payload[key].length > 0 && payload[key] != "Select")
      console.log("onClickGreyCheck bool: ", bool, "payload: ", payload)
      if (!bool) return

      payload.id = filterData.id
      const headers = {
        Authorization: `Token ${token}`
      }
      let response
      setLoading(true)
      if (!filterData.id) {
        response = await createAllowance(payload, headers)
      } else if (filterData.id) {
        response = await updateAllowance(filterData.id, payload, headers)
      }
      fetchAllowances()
      setLoading(false)
    } catch (error) {
      console.log("error adding a new allowance === ", error)
      setLoading(false)
    }
  }

  const deleteAllowanceHandle = async index => {
    try {
      const headers = {
        Authorization: `Token ${token}`
      }
      const { id } = allowances[index]
      console.log(allowances[index], index)
      const response = await deleteAllowance(id, headers)
      fetchAllowances()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setAllowances(allowancesData)
  }, [allowancesData])

  useEffect(() => {
    const sum = allowances.reduce(
      (acc, allowance) => Number(acc) + Number(allowance.price),
      0
    )
    setTotalAmount({ price__sum: sum })
  }, [allowances])

  useEffect(() => {
    let roles = []
    deptsSelected.forEach(dept => {
      roles = [...roles, ...dept.roles]
    })
    const rolesId = rolesSelected.map(role => role.id)
    const filteredRoles = roles.filter(function (role) {
      return rolesId.indexOf(role.id) !== -1
    })
    setRoleOptions(roles)
    setRolesSelected(filteredRoles)
  }, [deptsSelected])

  useEffect(() => {
    const deptsId = deptsSelected.map(dept => dept.id)
    const rolesId = rolesSelected.map(role => role.id)
    let filterResult = allowancesData.filter(function (item) {
      return deptsId.length ? deptsId.indexOf(item.department.id) !== -1 : true
    })
    filterResult = filterResult.filter(function (item) {
      return rolesId.length ? rolesId.indexOf(item.role.id) !== -1 : true
    })

    filterResult = filterResult.filter(function (item) {
      return filterObj.type ? filterObj.type === item.type.id : true
    })

    filterResult = filterResult.filter(function (item) {
      return filterObj.frequency ? filterObj.frequency === item.frequency : true
    })
    setAllowances(filterResult)
  }, [
    JSON.stringify(allowancesData),
    deptsSelected,
    rolesSelected,
    filterObj.type,
    filterObj.frequency
  ])

  return (
    <div className="wrapper">
      <div className="container dashboard manageAllowance-warpper">
        <Row className="title-wrapper">
          <Col>
            <span className="title">Assign Allowances</span>
          </Col>
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <Button className="assign-btn" onClick={() => setAssign(false)}>
              <span>Back</span>
            </Button>
          </Col>
        </Row>

        <div className="assign-allowance">
          <Container className="allowance">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Form.Group controlId="department">
                  <Form.Label className="department">
                    Select Department
                  </Form.Label>

                  <Dropdown
                    name="department"
                    width="100%"
                    options={departments}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    placeholder="Select"
                    onChangeHandler={dept => {
                      setDeptsSelected(dept)
                    }}
                    isMulti
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Form.Group controlId="role">
                  <Form.Label className="role">Select Role</Form.Label>

                  <Dropdown
                    name="role"
                    width="100%"
                    value={rolesSelected}
                    options={roleOptions}
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    placeholder="Select"
                    onChangeHandler={role => setRolesSelected(role)}
                    isMulti
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Form.Group controlId="allowance-type">
                  <Form.Label className="allowance-type">
                    Allowance Type
                  </Form.Label>

                  <Dropdown
                    isClearable
                    name="allowance-type"
                    width="100%"
                    getOptionLabel={option => option.title}
                    getOptionValue={option => option.id}
                    options={allowanceType}
                    placeholder="Select"
                    onChangeHandler={type => {
                      console.log(type)
                      if (type === null) {
                        return setFilterObj(prevState => ({
                          ...prevState,
                          type: ""
                        }))
                      }

                      setFilterObj(prevState => ({
                        ...prevState,
                        type: type.id
                      }))
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginBottom: "2rem" }}>
              <Col xs={12} sm={12} md={4} lg={4}></Col>
              <Col xs={12} sm={12} md={4} lg={4}></Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <Form.Group
                  controlId="allowance-frequency"
                  className="allowance-frequency"
                >
                  <Form.Label className="allowance-frequency">
                    Allowance Frequency
                  </Form.Label>

                  <Dropdown
                    isClearable
                    name="allowance-frequency"
                    width="100%"
                    options={allowanceFrquency}
                    placeholder="Select"
                    onChangeHandler={frequency => {
                      if (frequency === null) {
                        return setFilterObj(prevState => ({
                          ...prevState,
                          frequency: ""
                        }))
                      }
                      setFilterObj(prevState => ({
                        ...prevState,
                        frequency: frequency.value
                      }))
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row
              style={{
                overflowX: "auto"
              }}
            >
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="allowances-table">
                  <Form.Label className="">Allowances</Form.Label>

                  <AllowancesTable
                    departments={departments}
                    type={allowanceType}
                    data={allowances}
                    updateAllowanceData={updateAllowanceData}
                    onClickGreyCheck={onClickGreyCheck}
                    deleteAllowance={deleteAllowanceHandle}
                    loading={loading}
                  />
                </div>
              </Col>
            </Row>
            <Row className="total-sum-row">
              <Col xs={12} sm={12} md={4} lg={4}></Col>
              <Col xs={12} sm={12} md={4} lg={4} className="total-sum-col">
                <span>Total</span>
                <span>{`$ ${totalAmount.price__sum}`}</span>
              </Col>

              <Col xs={12} sm={12} md={4} lg={4}>
                <div className="buttons-row">
                  <Button
                    onClick={() => {
                      setAllowances(prevState => [
                        allowanceTemplateObj,
                        ...prevState
                      ])
                    }}
                  >
                    <span>Add Allowance</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}

const allowanceTemplateObj = {
  id: "",
  department: {
    id: "",
    title: "Select"
  },
  role: {
    id: "",
    title: "Select"
  },
  type: {
    id: "",
    title: "Select"
  },
  frequency: "Select",
  price: "",
  isNew: true
}

export const allowanceFrquency = [
  {
    value: "One-time",
    label: "One-time"
  },
  {
    value: "Monthly",
    label: "Monthly"
  },
  {
    value: "Every 3 months",
    label: "Every 3 months"
  },
  {
    value: "Every 6 months",
    label: "Every 6 months"
  },
  {
    value: "Yearly",
    label: "Yearly"
  }
]

export default AssignAllowance
