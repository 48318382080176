import React from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

const Toast = () => {
  return (
    <div>
      <ToastContainer />
    </div>
  )
}

export const notifySuccess = message =>
  toast.success(`${message}!`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  })

export const notifyError = message => {
  toast.error(`${message}!`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  })
}

export const notifyWarn = message => {
  toast.warn(`${message}!`, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
    // progressClassName: css({
    //   background:
    //     "repeating-radial-gradient(circle at center, red 0, blue, green 30px)"
    // })
  })
}

export default Toast
